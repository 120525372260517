import React from "react";
import { Box, Typography } from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ScheduleIcon from "@mui/icons-material/Schedule";

const NewFeatureComingSoon: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="inherit"
      textAlign="center"
      color="#555555" // Set the text color
    >
      <NewReleasesIcon sx={{ fontSize: 64, mb: 2, color: "#FFA500" }} />
      <Typography variant="h5" component="h1" mb={2}>
        New Feature Coming Soon
      </Typography>
      <Typography variant="body1" color="text.secondary">
        We're working on an exciting new feature for you. Stay tuned!
      </Typography>
      {/* <Box mt={2}>
        <ScheduleIcon sx={{ fontSize: 16, mr: 1, color: "#888888" }} />
        <Typography variant="body2" color="text.secondary">
          Estimated release date: August 2023
        </Typography>
      </Box> */}
    </Box>
  );
};

export default NewFeatureComingSoon;
