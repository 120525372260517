import { useState, SyntheticEvent, useEffect } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getAllTDLTestRequests } from "../../../apis/labs";
import { CustomTable } from "../../table/Table";
import { formatDate } from "../../../util/data.util";
import ShowTestResults from "./ShowTestResults";
import PrimaryButton from "../../buttons/PrimaryButton";
import ActionBack from "../../actionIcons/ActionBack";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../../redux/slice/loaderSlice";
import { createTestResult } from "../../../util/labs/labResultPdf";
import { testResultStatus } from "../../../util/appointment/util";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
interface TestRequestsProps {
  patientId?: string;
}

const AllRequestedTests: React.FC<TestRequestsProps> = ({ patientId }) => {
  const today = dayjs();
  const last15Days = today.subtract(15, "day");
  const [fromDate, setFromDate] = useState<string>(
    last15Days.format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState<string>(today.format("YYYY-MM-DD"));
  const [toError, setToError] = useState<boolean>(false);
  const [requestedTests, setRequestedTests] =
    useState<TDLRequestedTestResponse[]>();
  const [selectedTest, setSelectedTest] = useState<TDLRequestedTestResponse>();
  const [showResult, setShowResult] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { downloadFile } = useDownloadFile();

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(loading());

    const currentDate = new Date(toDate);
    currentDate.setDate(currentDate.getDate() + 1);
    const [response, error] = await getAllTDLTestRequests({
      fromDate: fromDate,
      toDate: currentDate.toISOString().split("T")[0],
    });
    if (response) {
      setRequestedTests(
        response.map((test: TDLRequestedTestResponse) => {
          return {
            ...test,
            FullName: `${capitalizeFirstLetter(
              test.patient.title
            )} ${capitalizeFirstLetter(
              test.patient.firstName
            )} ${capitalizeFirstLetter(test.patient.lastName)}`,
            RequestedTests: test.testCode.split(",").join(" | "),
            ResultStatus:
              (test.testResult?.Tests?.length || 0) > 0
                ? "Results Available"
                : "Pending",
          };
        })
      );
    } else {
      console.log(error);
    }
    dispatch(doneLoading());
  };

  const handleFromDateChange = (e: any) => {
    const selectedFromDate = dayjs(e.currentTarget.value);
    const selectedToDate = dayjs(toDate);

    if (selectedFromDate > selectedToDate) {
      setToError(true);
    } else {
      setToError(false);
    }

    setFromDate(e.currentTarget.value);
  };

  const handleToDateChange = (e: any) => {
    const selectedToDate = dayjs(e.currentTarget.value);

    if (selectedToDate > today) {
      setToError(true);
    } else {
      setToError(false);
    }

    setToDate(e.currentTarget.value);
  };

  const handleDownload = async (row: TDLRequestedTestResponse) => {
    dispatch(loading());
    await downloadFile(row.formUrl);
    dispatch(doneLoading());
  };

  const handleRowClick = (row: TDLRequestedTestResponse) => {
    setSelectedTest(row);
    setShowResult(true);
  };

  const columns = [
    {
      label: "Name",
      key: "FullName",
      sortable: true,
    },

    {
      label: "Requested Date",
      key: "whenCreated",
      sortable: true,
    },
    {
      label: "Requested Tests",
      key: "RequestedTests",
    },
    {
      label: "Result Status",
      key: "ResultStatus",
      sortable: true,
      style: testResultStatus,
    },
    {
      label: "Actions",
      key: "actions",
    },
  ];
  const actions: Action[] = [
    {
      label: "",
      onClick: handleDownload,
      tooltipLabel: "Download Form",
      icon: (
        <FileDownloadOutlinedIcon
          style={{ cursor: "pointer" }}
          color="primary"
        />
      ),
    },
    {
      label: "",
      onClick: handleRowClick,
      tooltipLabel: "View Results",
      icon: <VisibilityOutlinedIcon />,
      disableOn: ["Pending"],
      disableSelectorKey: "ResultStatus",
    },
  ];

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handlePrint = () => {
    selectedTest && createTestResult(selectedTest);
  };

  useEffect(() => {
    const getPatientTests = async (patientId: string) => {
      dispatch(loading());
      const [response, error] = await getAllTDLTestRequests({
        patientPublicId: patientId,
      });
      if (response) {
        setRequestedTests(
          response.map((test: any) => {
            return {
              ...test,
              FullName: `${capitalizeFirstLetter(
                test.patient.title
              )} ${capitalizeFirstLetter(
                test.patient.firstName
              )} ${capitalizeFirstLetter(test.patient.lastName)}`,
              RequestedTests: test.testCode.split(",").join(" | "),
              ResultStatus:
                (test.testResult?.Tests?.length || 0) > 0
                  ? "Results Available"
                  : "Pending",
            };
          })
        );
      } else {
        console.log(error);
      }
      dispatch(doneLoading());
    };
    if (patientId) {
      getPatientTests(patientId);
    }
  }, [patientId]);

  return (
    <div>
      {!showResult ? (
        <>
          {!patientId && (
            <>
              <Grid container xs={12} sm={12} md={12} mb={3}>
                <Typography variant="h5">Requested Tests</Typography>
              </Grid>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      type="date"
                      className="col-md-10"
                      label="From date"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: today.format("YYYY-MM-DD"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      className="col-md-10"
                      type="date"
                      label="To date"
                      value={toDate}
                      onChange={handleToDateChange}
                      error={toError}
                      helperText={
                        toError ? "Date cannot be in the future" : undefined
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: fromDate,
                        max: today.format("YYYY-MM-DD"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mb={3}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
          <Grid container xs={12} sm={12} md={12} spacing={2}>
            <Grid item xs={12} sm={12} md={12} p={2}>
              {requestedTests && (
                <CustomTable
                  data={requestedTests}
                  columns={columns}
                  actions={actions}
                  searchable={true}
                  dateFormatter={formatDate}
                  optionButtonText={"Book a test"}
                  defaultSort={{
                    key: columns[0].key,
                    direction: "asc",
                  }}
                  height={"50vh"}
                  overflow="auto"
                  tableHeight="600px"
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        showResult &&
        selectedTest?.testResult?.Tests && (
          <>
            <Grid container justifyContent="space-between">
              <Grid item>
                <ActionBack handleClick={() => setShowResult(false)} />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label={"Print Result"}
                  type={"button"}
                  onClick={handlePrint}
                  startIcon={<PrintOutlinedIcon />}
                />
              </Grid>
            </Grid>
            <ShowTestResults selectedTest={selectedTest} />
          </>
        )
      )}
    </div>
  );
};

export default AllRequestedTests;
