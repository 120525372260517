import { AZURE_URL } from "../constants/env-constants";

export const getAllSupplements = async () => {
  try {
    const response = await AZURE_URL.get("/supplement/get/all");
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const createNewSupplement = async (data: CreateSupplementRequest) => {
  try {
    const response = await AZURE_URL.post("/supplement/create", data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const updateSupplement = async (data: UpdateSupplementRequest) => {
  try {
    const response = await AZURE_URL.put("/supplement/update", data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteSupplement = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/supplement/delete?publicId=${id}`
    );

    return [response, null];
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};
