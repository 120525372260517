import { AZURE_URL } from "../constants/env-constants";

export const addFirstConsultation = async (
  firstConsultationData: FirstConsultationPostRequest
) => {
  try {
    const response = await AZURE_URL.post(
      "/consultation/first/create",
      firstConsultationData
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getFirstConsultations = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/consultation/first/get?patientPublicId=${patientId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return error.response;
  }
};

export const addFollowUpConsultation = async (
  followUpData: FollowUpPostRequest
) => {
  try {
    const response = await AZURE_URL.post(
      "/consultation/followUp/create",
      followUpData
    );
    return [response.data, null];
  } catch (error: any) {
    return error.response;
  }
};

export const getFollowUpConsultations = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/consultation/followUp/get?patientPublicId=${patientId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return error.response;
  }
};

export const addConsultation = async (
  consultationData: ConsultationPostRequest
) => {
  try {
    const response = await AZURE_URL.post(
      "/consultation/generic/create",
      consultationData
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getConsultations = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/consultation/generic/get?patientPublicId=${patientId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return error.response;
  }
};
