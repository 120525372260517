import { AZURE_URL } from "../constants/env-constants";

export const createEvent = async (event: CreateEvent) => {
  try {
    const response = await AZURE_URL.post(`/event/create`, event);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getEventByPatientID = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?patientPublicId=${patientId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return error.response.data;
  }
};

export const updateEventStage = async (event: UpdateEventRequest) => {
  try {
    const response = await AZURE_URL.put("event/update", event);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const archiveEvent = async (event: ArchiveEventRequest) => {
  try {
    const response = await AZURE_URL.put("event/update", event);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getEventsByPathwayId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(`/event/get?pathwayPublicId=${id}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getAllEvents = async () => {
  try {
    const response = await AZURE_URL.get("/event/get/all");
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};
export const getAllEventByIds = async (
  patientId: string,
  pathwayId: string
) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?patientPublicId=${patientId}&pathwayPublicId=${pathwayId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getArchivedEventsByPathwayId = async (pathwayId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?pathwayPublicId=${pathwayId}&archived=true`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getArchivedEventsByPatientId = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/event/get?patientPublicId=${patientId}&archived=true`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};
