import { AZURE_URL } from "../constants/env-constants";

export const getWixBookings = async (query: any) => {
  try {
    const response = await AZURE_URL.post(
      "/booking/loadWixBookings/query",
      query
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error:" + error.response];
  }
};
