import clientLogo from "../assets/p4 pregnancy/p4pregnancyLogo.png";
import clientDocumentLogo from "../assets/p4 pregnancy/400PngdpiLogo.png";
export const BRAND_NAME = "P4 Fertility";
export const BRAND_COLOR_LIGHT = "rgb(0,184,222)";
export const BRAND_COLOR_DARK = "rgb(1,46,111)";
export const GYNAE_BRAND_NAME = "P4 Gynaecare";
export const GYNAE_BRAND_COLOR_DARK = "rgb(148,33,97)";

export const getClientLogo = () => {
  return clientLogo;
};
export const getClientDocumentLogo = () => {
  return clientDocumentLogo;
};

export const getClientBackgroundColor = () => {
  //main bg,
  //clinic bg
  console.log(window.location.pathname);
  return window.location.pathname === "/login" ? "#f4f4f4" : "#f4ccee";
};

export const getClientBrandColorDark = () => {
  //buttons
  return "#d24c73";
};

export const getClientBrandColorLight = () => {
  return "#ffadad";
};
