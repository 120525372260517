import { Box, Grid, Typography } from "@mui/material";
import {
  formatDateStr,
  formatNameValuePairs,
  getNameValueObject,
} from "../../util/data.util";
import ShowNameValue from "./ShowNameValue";
import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  FemaleFertilityDetailsForm,
  FertilityDetails,
} from "../../util/patient/firstConsultation.form.helper";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import HeaderElement from "./HeaderElement";
import ShowTldrawData from "../tlDraw/ShowTldrawData";

interface ConsultationProps {
  data: any;
  setShowTable: (value: boolean) => void;
  handleDownload?: any;
}
const ShowPregnancyConsultation: React.FC<ConsultationProps> = ({
  data,
  setShowTable,
}) => {
  const [previousTreatment, setPreviousTreatment] = useState<any>();
  const [, setIsLoadingSVG] = useState(true);
  const { getSVGFile } = useDownloadFile();
  const [svgContent, setSvgContent] = useState<any>();
  const canvasRef = useRef<SVGSVGElement | null>(null);
  const [svgWidth] = useState(900);
  const [, setSvgHeight] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(data);
    // if (data.handwrittenNotes?.length) {
    //   download(data.handwrittenNotes[0].url);
    // }
  }, []);

  const download = async (url: string) => {
    dispatch(loading());
    const [response, error] = await getSVGFile(url);
    setIsLoadingSVG(false);
    setSvgContent(response);
    dispatch(doneLoading());
  };

  // useEffect(() => {
  //   const svg = canvasRef.current;
  //   if (!svg) return;

  //   svg.innerHTML = svgContent.toString();
  // }, [svgContent]);

  useEffect(() => {
    const handleResize = () => {
      // Calculate the new height based on the width and aspect ratio
      const newHeight = (svgWidth * svgContent?.height) / svgContent?.width;
      setSvgHeight(newHeight);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial calculation for the height
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [svgWidth]);

  return (
    <div id="print-content">
      <HeaderElement
        name={data?.name}
        whenCreated={data?.whenCreated}
        backFunction={() => {
          setShowTable(true);
        }}
      />
      <Grid container>
        <Grid container xs={12} sm={12} md={12} padding={2}>
          {data.patient && (
            <Grid item xs={12} sm={12} md={12}>
              <hr />
            </Grid>
          )}
          {data.patient && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  p: 3,
                }}
              >
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Parity</b>
                    </Typography>
                  </Box>
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">{data.parity}</Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>EDD</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">
                      {formatDateStr(data.edd)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Gestation</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">{data.gestation}</Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Medical Issues</b>
                    </Typography>
                  </Box>
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">
                      {data.medicalIssues}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Medications</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">{data.medications}</Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Obstetric History</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">
                      {data.obstetricHistory}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Medical History</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">
                      {data.medicalHistory}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Surgical History</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">
                      {data.surgicalHistory}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Gynae History</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">{data.gynaeHistory}</Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Family History</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">
                      {data.familyHistory}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Consultation</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">{data.consultation}</Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: 2, width: "50%", display: "flex" }}>
                  <Box key={"label"} sx={{ width: "40%" }}>
                    <Typography variant="subtitle1">
                      <b>Additional Comments</b>
                    </Typography>
                  </Box>{" "}
                  <Box key={"value"} sx={{ width: "50%" }}>
                    <Typography variant="body1">
                      {data.additionalComments}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}

          {data.handwrittenNotes &&
            data.handwrittenNotes?.length > 0 &&
            data.handwrittenNotes?.map((item: any) => (
              <ShowTldrawData url={item.url} />
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowPregnancyConsultation;
