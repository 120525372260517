export const HycosyFormInputs = [
  {
    label: "Clinical Indication",
    type: "text",
    id: "clinicalIndication",
    placeholder: "Enter here",
    required: false,
    options: ["Subfertility"],
  },
  {
    label: "Uterus",
    type: "text",
    id: "uterus",
    placeholder: "Enter here",
    required: false,
    options: ["Anteverted", "Retroverted"],
  },
  {
    label: "Cavity",
    type: "text",
    id: "cavity",
    placeholder: "Enter here",
    required: false,
    options: ["Normal", "Endometrial Polyp", "Submucosal Fibroid"],
  },

  {
    label: "Endometrial Thickness",
    type: "text",
    id: "endometrialThickness",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Fibroid / Polyp",
    type: "text",
    id: "fibroidOrPolyp",
    placeholder: "Enter here",
    required: false,
    options: ["Absent", "Present"],
  },
  {
    label: "Left Tube",
    type: "text",
    id: "leftTube",
    placeholder: "Enter here",
    required: false,
    options: ["Open", "Absent", "Bloked(Spill not seen)", "Hydrosalphinx"],
  },
  {
    label: "Right Tube",
    type: "text",
    id: "rightTube",
    placeholder: "Enter here",
    required: false,
    options: ["Open", "Absent", "Bloked(Spill not seen)", "Hydrosalphinx"],
  },
  {
    label: "Adnexa",
    type: "text",
    id: "adnexa",
    placeholder: "Enter here",
    required: false,
    options: ["Normal", "Abnormal"],
  },
  {
    label: "Left Ovary",
    type: "text",
    id: "leftOvary",
    placeholder: "Enter here",
    required: false,
    options: ["Normal", "Abnormal"],
  },
  {
    label: "Right Ovary",
    type: "text",
    id: "rightOvary",
    placeholder: "Enter here",
    required: false,
    options: ["Normal", "Abnormal"],
  },
  {
    label: "Antral Follicle Count",
    type: "text",
    id: "antralFollicleCount",
    placeholder: "Enter here",
    required: false,
  },

  {
    label: "Recommendation",
    type: "text",
    id: "recommendation",
    placeholder: "Enter here",
    required: false,
    options: [
      "Assited conception treatemnet",
      "Continue natural trying",
      "Surgery as advised",
    ],
  },
  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: "Enter here",
    required: false,
    tag: "textarea",
    maxLength: 250,
    minRows: 4,
  },
];

export const PregnancyFormInputs = [
  {
    label: "Gestational Age By Date",
    type: "text",
    id: "gestationalAgeByDate",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Gestational Sac",
    type: "text",
    id: "gestationalSac",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "CRL",
    type: "text",
    id: "crl",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Fetal Heart Beats",
    type: "text",
    id: "fetalHeartBeats",
    placeholder: "Enter here",
    required: false,
  },
  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: "Enter here",
    required: false,
    tag: "textarea",
    maxLength: 250,
    minRows: 4,
  },
];

export const UltrasoundFormInputs = [
  {
    label: "Uterus",
    type: "text",
    id: "uterus",
    placeholder: "Enter here",
    required: false,
    options: ["Anteverted", "Retroverted"],
  },
  {
    label: "Cavity",
    type: "text",
    id: "cavity",
    placeholder: "Enter here",
    required: false,
    options: [],
  },
  {
    label: "Endometrial Thickness",
    type: "text",
    id: "endometrialThickness",
    placeholder: "Enter here",
    required: false,
    options: [],
  },
  {
    label: "Fibroid / Polyp",
    type: "text",
    id: "fibroidOrPolyp",
    placeholder: "Enter here",
    required: false,
    options: ["Absent", "Present"],
  },
  {
    label: "Left Ovary",
    type: "text",
    id: "leftOvary",
    placeholder: "Enter here",
    required: false,
    options: ["Normal", "Abnormal"],
  },
  {
    label: "Right Ovary",
    type: "text",
    id: "rightOvary",
    placeholder: "Enter here",
    required: false,
    options: ["Normal", "Abnormal"],
  },
  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: "Enter here",
    required: false,
    tag: "textarea",
    maxLength: 250,
    minRows: 4,
  },
];
