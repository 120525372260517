import jsPDF from "jspdf";
import {
  addCreatedDate,
  addFooter,
  addHeader,
  addPatientInfo,
} from "../pdf.util";
import { todaysDate } from "../getTodaysDate";
import { BRAND_NAME } from "../../constants/BrandConstants";
//created by Ranjit Jeve

let globalPosition = 10;
const marginLeft = 15; //
const textFontPoint = 11;
const commonSpaceBetween = 10; //
const drNamePosition = 40; // globalPosition + drNamePosition
const approvedPosition = 5;
const clinicAddress = "81 Harborne Rd,\nBirmingham B15 3HG";
const email = "secretary@p4pregnancy.co.uk";
const phone = "0121 314 4763";
export const downloadFertilityLetter = async (patientData: P4LetterType) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone);
  doc = addFooter(doc);
  globalPosition = addPatientInfo(doc, patientData);
  globalPosition = addCreatedDate(
    doc,
    patientData.letterDate || todaysDate(),
    globalPosition
  );
  addLetterData(doc, patientData);
  addSignature(doc, patientData.signature, patientData);
  addFooter(doc);
  doc.save(
    "Letter_" + patientData.patientName + "_" + patientData.patientDOB + ".pdf"
  );

  globalPosition = 10;
  return;
};

const addLetterData = (doc: any, patientData: P4LetterType) => {
  doc
    .setFontSize(textFontPoint)
    .text(
      patientData.content,
      marginLeft,
      getGlobalPosition(commonSpaceBetween),
      { maxWidth: 170 },
      null
    );
};

const addSignature = (doc: any, drName: string, patientData: P4LetterType) => {
  const letterDataHeight = doc.getTextDimensions(patientData.content, {
    fontSize: textFontPoint,
    maxWidth: doc.internal.pageSize.getWidth() - marginLeft * 2,
  }).h;

  const signaturePosition = getGlobalPosition(
    letterDataHeight + commonSpaceBetween * 2
  );

  const signatureHeight = doc.getTextDimensions(drName, {
    fontSize: textFontPoint,
    maxWidth: doc.internal.pageSize.getWidth() - marginLeft * 2,
  }).h;

  const elctronicallyPosition = getGlobalPosition(
    signatureHeight + approvedPosition
  );
  doc
    .text(drName, marginLeft, signaturePosition)
    .text("(Electronically approved)", marginLeft, elctronicallyPosition);
};

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};
