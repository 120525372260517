import { AZURE_URL } from "../constants/env-constants";

export const uploadFile = async (data: any) => {
  try {
    const response = await AZURE_URL.post("/storage/upload", data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
export const downloadFileByUrl = async (data: { url: string }) => {
  try {
    const response = await AZURE_URL.get("/storage/download", {
      params: data,
      responseType: "blob",
    });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

// export const downloadFile2 = async (
//   containerName: string,
//   filename: string
// ) => {
//   try {
//     const response = await API_URL().get(
//       `/storage/download?containerName=${containerName}&fileName=${filename}`
//     );
//     return response.data;
//   } catch (error: any) {
//     return error.response.data;
//   }
// };
