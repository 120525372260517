import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomTable } from "../table/Table";
import { deleteReportById } from "../../apis/reports";
import { useSelector } from "react-redux";
import {
  combineArrays,
  formatDate,
  formatToDateTime,
  objectBuilderForPDF,
} from "../../util/data.util";
import { useDispatch } from "react-redux";
import { doneLoading } from "../../redux/slice/loaderSlice";
import {
  HycosyFormInputs,
  PregnancyFormInputs,
  UltrasoundFormInputs,
} from "../../util/reportsCreation/report.formHelper";
import {
  ProtocolDonorSpermForm,
  ProtocolFreshCycleForm,
  ProtocolFrozenCycleForm,
  ProtocolIBSpainForm,
  patientAndPartnerFormInputs,
} from "../../util/protocolCreation/protocol.formHelper";
import {
  CONSULTATION,
  FILE_DELETED_SUCCESSFULLY,
  FILE_DELETE_FAILED,
  FIRST_CONSULTATION,
  FOLLOW_UP_CONSULTATION,
  HYCOSY,
  NOTE,
  P4_FERTILITY_LETTER,
  P4_GYNAECARE_LETTER,
  PREGNANCY_SCAN,
  PROTOCOL_DONOR_SPERM,
  PROTOCOL_FRESH_CYCLE,
  PROTOCOL_FROZEN_CYCLE,
  PROTOCOL_IBSPAIN,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  ULTRASOUND_SCAN,
} from "../../constants/Constants";
import ShowConsultation from "../dataViewComponents/ShowConsultation";
import ComponentLoading from "../loading/ComponentLoading";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmationDialog from "../dialogBoxes/ConfirmationDialog";
import { LetterForm, NoteForm } from "../../util/forms/form.helper";
import ShowNote from "../dataViewComponents/ShowNote";
import ShowReports from "../dataViewComponents/ShowReports";
import usePDFDownload from "../../hooks/usePDFDownload";
import ShowLetter from "../dataViewComponents/ShowLetter";
import ShowPregnancyConsultation from "../dataViewComponents/ShowPregnancyConsultation";
import { PregnancyConsultationForm } from "../../util/patient/consultationForm.helper";
interface ReportRowInterface {
  publicId: string;
  name: string;
  url: string;
  type: string;
  patient: PatientInformation;
  reportFields: [];
  createdBy: string;
  whenCreated: Date;
  read: false;
}

const ProgressNotes = React.memo(() => {
  const [progressNotes, setProgressNotes] = useState<any>();
  // const progressNotesNew = useSelector<RootState, any[]>( (state) => state.progressNotes);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [selectedReport, setSelectedReport] = useState<any>();
  const [selectedNote, setSelectedNote] = useState<Note | null>();
  const [, setShowFollowUp] = useState<boolean>(false);
  const [consultation, setConsultation] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<ReportRowInterface | null>();
  const [, setShowNote] = useState<boolean>(false);
  const [pregnancyConsultation, setPregnancyConsultation] = useState<any>();
  const downloadPDF = usePDFDownload();
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const partnerObject: any = useSelector<PartnerState>(
    (state) => state.partner
  );
  const dispatch = useDispatch();
  const progressNotesState: any = useSelector<ProgressNotesState>(
    (state) => state.progressNotes
  );

  const [selectedLetter, setSelectedLetter] =
    useState<P4LetterResponse | null>();

  const handleRowClick = (row: any) => {
    if (row) {
      if (row.type === CONSULTATION) {
        setShowTable(false);
        setShowFollowUp(true);
        setSelectedReport(null);
        if (row.name === CONSULTATION) {
          setPregnancyConsultation(row);
          setConsultation(null);
        } else {
          setPregnancyConsultation(null);
          setConsultation(row);
        }
        setSelectedNote(null);
        setSelectedLetter(null);
      } else if (row.type === NOTE) {
        setShowTable(false);
        setSelectedNote(row);
        setPregnancyConsultation(null);
        setShowNote(true);
        setSelectedReport(null);
        setShowFollowUp(false);
        setConsultation(null);
        setSelectedLetter(null);
      } else if (row.type.toString().includes("Letter")) {
        setShowTable(false);
        setPregnancyConsultation(null);
        const data = {
          ...row,
          pdfData: objectBuilderForPDF(
            patientObject.patient,
            partnerObject.partner,
            {
              content: row.content,
              letterDate: formatDate(row.whenCreated),
              signature: row.signature,
            }
          ),
        };

        setSelectedNote(null);
        setShowNote(true);
        setSelectedReport(null);
        setShowFollowUp(false);
        setConsultation(null);
        setSelectedLetter(data);
      } else {
        setPregnancyConsultation(null);
        const updatedRow = {
          ...row,
          newFields: combineArrays(
            patientAndPartnerFormInputs,
            getForm(row.type) || [],
            row.reportFields
          ),
        };
        setConsultation(null);
        setShowFollowUp(false);
        setSelectedReport(updatedRow);
        setShowTable(false);
        setSelectedNote(null);
        setSelectedLetter(null);
      }
    }
  };

  const getForm = (form: string) => {
    switch (form) {
      case HYCOSY:
        return HycosyFormInputs;
      case PREGNANCY_SCAN:
        return PregnancyFormInputs;
      case ULTRASOUND_SCAN:
        return UltrasoundFormInputs;
      case PROTOCOL_FRESH_CYCLE:
        return ProtocolFreshCycleForm;
      case PROTOCOL_DONOR_SPERM:
        return ProtocolDonorSpermForm;
      case PROTOCOL_FROZEN_CYCLE:
        return ProtocolFrozenCycleForm;
      case PROTOCOL_IBSPAIN:
        return ProtocolIBSpainForm;
      case FIRST_CONSULTATION:
        return;
      case FOLLOW_UP_CONSULTATION:
        return;
      case NOTE:
        return NoteForm;
      case P4_FERTILITY_LETTER:
        return LetterForm;
      case P4_GYNAECARE_LETTER:
        return LetterForm;
      case CONSULTATION:
        return PregnancyConsultationForm;
    }
  };

  const openConfirmModal = (row: ReportRowInterface) => {
    setToDelete(row);
    setConfirmModalOpen(true);
  };
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setToDelete(null);
  };

  const handleDelete = async (row: ReportRowInterface) => {
    // dispatch(loading());
    const [response, error] = await deleteReportById(row.publicId);
    console.log(response, error);
    if (response) {
      dispatch(
        activateSnackbar({
          active: true,
          message: `${FILE_DELETED_SUCCESSFULLY}  ${row.name}`,
          variant: SNACKBAR_SUCCESS,
        })
      );
      // getReports();
    } else {
      dispatch(
        activateSnackbar({
          active: true,
          message: `${FILE_DELETE_FAILED}  ${row.name}`,
          variant: SNACKBAR_ERROR,
        })
      );
      dispatch(doneLoading());
    }
    closeConfirmModal();
    dispatch(doneLoading());
  };

  const columns = [
    {
      label: "Date",
      key: "whenCreated",
      sortable: true,
    },
    {
      label: "Name",
      key: "name",
      sortable: true,
    },
    {
      label: "Type",
      key: "type",
      sortable: true,
    },
    // {
    //   label: "Actions",
    //   key: "actions",
    // },
  ];

  const actions: Action[] = [
    {
      label: "",
      onClick: openConfirmModal,
      tooltipLabel: "Delete",
      icon: <DeleteOutlinedIcon style={{ cursor: "pointer" }} color="error" />,
    },
  ];

  useEffect(() => {
    if (!progressNotesState.loading) {
      setProgressNotes(progressNotesState.data);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [progressNotesState]);

  const downloadRecord = (data: any) => {
    console.log(data);
    downloadPDF(data);
  };
  return (
    <>
      {isLoading ? (
        <ComponentLoading />
      ) : (
        <Grid container>
          {showTable ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ overflow: "auto", height: 700 }}
            >
              {progressNotes && (
                <CustomTable
                  data={progressNotes}
                  columns={columns}
                  onRowClick={handleRowClick}
                  searchable={true}
                  // actions={actions}
                  dateFormatter={formatToDateTime}
                  defaultSort={{
                    key: columns[0].key,
                    direction: "desc",
                  }}
                  height={"70vh"}
                />
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              {selectedReport && !showTable && (
                <ShowReports
                  data={selectedReport}
                  setShowTable={setShowTable}
                  handleDownload={downloadRecord}
                />
              )}

              {selectedLetter && !showTable && (
                <ShowLetter
                  letter={selectedLetter}
                  setShowTable={setShowTable}
                  handleDownload={downloadRecord}
                />
              )}
              {consultation && !showTable && (
                <ShowConsultation
                  setShowTable={setShowTable}
                  data={consultation}
                  handleDownload={downloadRecord}
                />
              )}
              {pregnancyConsultation && !showTable && (
                <ShowPregnancyConsultation
                  setShowTable={setShowTable}
                  data={pregnancyConsultation}
                  handleDownload={downloadRecord}
                />
              )}
              {selectedNote && !showTable && (
                <ShowNote setShowTable={setShowTable} note={selectedNote} />
              )}
            </Grid>
          )}
          {toDelete && (
            <ConfirmationDialog
              open={confirmModalOpen}
              onClose={closeConfirmModal}
              title={`Are you sure you want to delete ${toDelete?.name}?`}
              onCancel={closeConfirmModal}
              onDelete={() => handleDelete(toDelete)}
            />
          )}
        </Grid>
      )}
    </>
  );
});

export default ProgressNotes;
