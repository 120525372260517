import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import SearchPatient from "../fromComponents/SearchPatient";
import EditableBox from "../adminCenterComponents/EditableBox";
import { getPatientByPublicId } from "../../apis/patients";
import { formatDate } from "../../util/data.util";
import TestChips from "./TopTests";
import PrimaryButton from "../buttons/PrimaryButton";
import SelectStaff from "../select/SelectStaff";
import { useForm, FieldValues } from "react-hook-form";
import {
  TDLRequestDetailCheckbox,
  TDLRequestDetails,
} from "../../util/labs/tdl";
import TextInputFull from "../fromComponents/TextInputFull";
import { createTDLTest } from "../../apis/labs";
import useSnackbar from "../../hooks/useSnackbar";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";

const CreateTest: React.FC<CreateTestProps> = ({ patientId }) => {
  const [selectedPatient, setSelectedPatient] = useState<{
    publicId: string;
    name: string;
  }>();

  const [patient, setPatient] = useState<PatientInformation>();
  const [selectedTests, setSelectedTests] = useState<TDLTest[]>([]);
  const { register, handleSubmit, unregister, setValue } =
    useForm<FieldValues>();
  const [selectedStaffId, setSelectedStaffId] = useState<string>();
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const getPatientInformation = async (id: string) => {
    dispatch(loading());
    const response = await getPatientByPublicId(id);
    if (response) {
      setPatient(response.data);
    } else {
      errorSnackbar("Failed to get patient details.");
    }
    dispatch(doneLoading());
  };

  useEffect(() => {
    if (selectedPatient) {
      getPatientInformation(selectedPatient.publicId);
    }
  }, [selectedPatient]);

  const onSubmit = async (data: any) => {
    let obj: any = {
      ...data,
      accountTo: "D",
      isFasting: data.isFasting?.length > 0,
      isGrey: data.isGrey?.length > 0,
      needsKit: data.needsKit?.length > 0,
      needsVenous: data.needsVenous?.length > 0,
      staffPublicId: selectedStaffId,
      patientPublicId: patient?.publicId,
      gender: patient?.gender,
      tests: selectedTests.map((item) => {
        delete item.label;
        return item;
      }),
    };
    dispatch(loading());
    const [response] = await createTDLTest(obj);
    if (response) {
      successSnackbar(`Test created successfully.`);
    } else {
      errorSnackbar("Failed to create the test.");
    }
    dispatch(doneLoading());
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography variant="h6" mb={2}>
            Book A Test
          </Typography>
          <SearchPatient
            label={"Search Patient"}
            patient={undefined}
            setPatient={setSelectedPatient}
          />
        </Grid>
        {patient && (
          <Grid container md={12} margin={3}>
            <Grid item md={6}>
              <EditableBox
                label={"Name"}
                currentValue={`${patient.title || ""} ${patient.firstName} ${
                  patient.lastName || ""
                }`}
                name={""}
              />
              <EditableBox
                label={"Gender"}
                currentValue={patient.gender || ""}
                name={""}
              />
              <EditableBox
                label={"Address"}
                currentValue={`${
                  patient.address?.street1
                    ? `${patient.address?.street1}, `
                    : ""
                }${patient.address?.city} ${patient.address?.postalCode}`}
                name={""}
              />
            </Grid>
            <Grid item md={6}>
              <EditableBox
                label={"Patient Id"}
                currentValue={patient?.publicId}
                name={""}
              />

              <EditableBox
                label={"Date of Birth"}
                currentValue={formatDate(
                  patient?.dateOfBirth || patient?.dateOfBirthStr || ""
                )}
                name={""}
              />
            </Grid>
          </Grid>
        )}

        {patient && (
          <>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <hr />
                <Typography variant="h6">Frequently Selected Tests</Typography>
                <TestChips setSelectedTests={setSelectedTests} />
                <hr />
                <Typography variant="h6">Additional Details</Typography>
                <div className="row">
                  <div className="col-sm-6 p-2">
                    <SelectStaff
                      label={"Attending Consultant"}
                      id="staffPublicId"
                      setValue={(value: string, staffId: string) => {
                        setSelectedStaffId(staffId);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 p-2">
                    <div className="row ms-1">
                      {TDLRequestDetails?.map((item: InputFieldType) => {
                        return (
                          <TextInputFull
                            keyName={`${item.id}`}
                            register={register}
                            placeholder={item.placeholder}
                            id={`${item.id}`}
                            label={item.label}
                            type={item.type}
                            listName={`${item.id}list`}
                            options={item.options}
                            required={item.required}
                            tag={item.tag}
                            maxLength={item.maxLength}
                            minRows={item.minRows}
                            checkboxOptions={item.checkboxOptions}
                            setValue={setValue}
                            unregister={unregister}
                            actionOn={item.actionOn}
                            radioOptions={item.radioOptions}
                            children={item.children}
                            boxSize={item.boxSize}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 p-2">
                    <div className="row ms-1">
                      {TDLRequestDetailCheckbox?.map((item: InputFieldType) => {
                        return (
                          <TextInputFull
                            keyName={`${item.id}`}
                            register={register}
                            placeholder={item.placeholder}
                            id={`${item.id}`}
                            label={item.label}
                            type={item.type}
                            listName={`${item.id}list`}
                            options={item.options}
                            required={item.required}
                            tag={item.tag}
                            maxLength={item.maxLength}
                            minRows={item.minRows}
                            checkboxOptions={item.checkboxOptions}
                            setValue={setValue}
                            unregister={unregister}
                            actionOn={item.actionOn}
                            radioOptions={item.radioOptions}
                            children={item.children}
                            boxSize={item.boxSize}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <Typography variant="caption">
                  * Note - Any Kit Ordered will be sent directly to{" "}
                  {patient?.title || ""} {patient?.firstName}{" "}
                  {patient?.lastName || ""}
                </Typography>
                <Grid item xs={12} pb={5} pt={3}>
                  <Grid container justifyContent="flex-end" spacing={1}>
                    <PrimaryButton label={"submit"} type={"submit"} />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default CreateTest;
