import { AZURE_URL } from "../constants/env-constants";

export const getAllTeams = async () => {
  try {
    const response = await AZURE_URL.get("/staff/team/get/all");
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server Error: " + error.response];
  }
};

export const deleteTeam = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/staff/team/delete?publicId=${id}`
    );
    return [response, null];
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};

export const createNewTeam = async (data: CreateTeamRequest) => {
  try {
    const response = await AZURE_URL.post("/staff/team/create", data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};
