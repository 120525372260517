import React, { useEffect, useState } from "react";
import {
  Genders,
  Titles,
  PatientEditFormInputs,
} from "../../util/patient/patient.form.helper";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { getAllStaff } from "../../apis/staff";
import {
  getAllAllergies,
  getPatientByPublicId,
  updatePatient,
} from "../../apis/patients";
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SUCCESS_PATIENT_UPDATED,
  WENT_WRONG_MSG,
} from "../../constants/Constants";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { calculateAge, formatDate, formatDateStr } from "../../util/data.util";
import { addPartnerInformation } from "../../redux/slice/partnerInformation";
import { addPatientInformation } from "../../redux/slice/patientInformation";
import { parseDate } from "../../util/data.util";
import MultiSelectInput from "../fromComponents/MultiSelectInput";
import BMICalculator from "../bmiCalculator/BMICalculator";

const defaultFormData: formData = {
  firstName: "",
  title: "",
  lastName: "",
  gender: "",
  birthDate: "",
  address: {
    street1: "",
    city: "",
    state: "",
    postalCode: "",
  },
  patientDetails: {},
  allergies: "",
  phone: "",
  email: "",
  ownerStaffPublicId: "",
};

const PatientEditForm = React.memo<PatientEditFormProps>(
  ({ isOpen, setIsOpen, header, initialData, setInitialData, setReload }) => {
    const [formData, setFormData] = useState<formData>(defaultFormData);
    const [gender, setGender] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [ownerStaff, setOwnerStaff] = useState<string>();
    const [staffs, setStaffs] = useState<StaffInformation[]>([]);
    const [allergies, setAllergies] = useState<string[]>([]);
    const [value, setValue] = useState<string[]>([]);
    const patientObject: any = useSelector<PatientState>(
      (state) => state.patient
    );
    const partnerObject: any = useSelector<PartnerState>(
      (state) => state.partner
    );
    const { publicId: patientPublicId } = patientObject.patient;
    const { publicId: partnerPublicId } = partnerObject.partner;
    const dispatch = useDispatch();
    const [isRemovePartner, setIsRemovePartner] = useState<boolean>(false);

    let staffName = `${initialData.ownerStaff.title} ${initialData.ownerStaff.firstName} ${initialData.ownerStaff.lastName}`;

    const updateState = async () => {
      if (partnerPublicId) {
        await getPatientByPublicId(partnerPublicId).then(({ data }) => {
          data.dateOfBirth = data.dateOfBirth
            ? formatDate(data.dateOfBirth)
            : data.dateOfBirthStr
            ? formatDateStr(data.dateOfBirthStr)
            : "";
          data.allergies = data.allergies ? data.allergies.split(", ") : [];
          data.age = calculateAge(data.dateOfBirth);
          dispatch(addPartnerInformation(data));
        });
      }
      if (patientPublicId) {
        await getPatientByPublicId(patientPublicId).then(({ data }) => {
          data.dateOfBirth = data.dateOfBirth
            ? formatDate(data.dateOfBirth)
            : data.dateOfBirthStr
            ? formatDateStr(data.dateOfBirthStr)
            : "";
          data.allergies = data.allergies ? data.allergies.split(", ") : [];
          data.age = calculateAge(data.dateOfBirth);
          dispatch(addPatientInformation(data));
        });
      }
    };
    const handleSelectChange = (event: SelectChangeEvent) => {
      const { value, name } = event.target;
      if (name === "gender") {
        setGender(value as string);
      } else if (name === "title") {
        setTitle(value as string);
      } else {
        setOwnerStaff(value);
      }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      console.log(name, value);
      if (name.split(".").length === 1) {
        if (name && value)
          setFormData((values) => ({ ...values, [name]: value }));
      } else {
        setFormData((values) => ({
          ...values,
          address: { ...values.address, [name.split(".")[1]]: value },
        }));
      }
    };

    const handleBMIChange = (name: string, value: number | string) => {
      console.log(name, value);

      setFormData((values) => ({
        ...values,
        patientDetails: {
          ...values.patientDetails,
          [name.split(".")[1]]: value,
        },
      }));
    };
    const onSubmit = async (event: { preventDefault: () => void }) => {
      event.preventDefault();

      let obj: formData = defaultFormData;

      if (formData) {
        obj = { ...formData };
      }
      if (gender) {
        obj = { ...obj, gender: gender };
      }
      if (title) {
        obj = { ...obj, title: title };
      }
      if (value) {
        obj = { ...obj, allergies: value.toString() };
      }
      if (ownerStaff) {
        staffs.forEach((element) => {
          if (
            `${element.title} ${element.firstName} ${element.lastName}` ===
            ownerStaff
          )
            obj = { ...obj, ownerStaffPublicId: element.publicId };
        });
      }

      Object.keys(obj).forEach((key) => {
        if (obj[key as keyof formData] === "") {
          delete obj[key as keyof formData];
        }
        if (key === "address") {
          if (Object.keys(obj.address).length > 0) {
            Object.keys(obj.address).forEach((key) => {
              if (obj.address[key as keyof Address] === "") {
                delete obj.address[key as keyof Address];
              }
            });
          } else {
            if (obj[key as keyof formData] === "") {
              delete obj[key as keyof formData];
            }
          }
        }
      });

      if (isRemovePartner) {
        obj = { ...obj, partnerPublicId: "" };
      }
      console.log("allergies", value);
      if (obj) {
        console.log(obj);
        const [response, error] = await updatePatient({
          ...obj,
          publicId: initialData.publicId,
        });
        if (response) {
          if (response) {
            setIsOpen(false);
            dispatch(
              activateSnackbar({
                active: true,
                message: SUCCESS_PATIENT_UPDATED,
                variant: SNACKBAR_SUCCESS,
              })
            );
            // window.location.reload();
          } else {
            dispatch(
              activateSnackbar({
                active: true,
                message: WENT_WRONG_MSG,
                variant: SNACKBAR_ERROR,
              })
            );
          }
        } else {
          dispatch(
            activateSnackbar({
              active: true,
              message: error || WENT_WRONG_MSG,
              variant: SNACKBAR_ERROR,
            })
          );
        }
      }
    };

    const getAllergies = async () => {
      const [response, error] = await getAllAllergies();

      if (response) {
        setAllergies(response);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const [response, error] = await getAllStaff();
          if (response) {
            setStaffs(response);
          } else {
            dispatch(
              activateSnackbar({
                active: true,
                message: "Failed to get the staffs" + error,
                variant: SNACKBAR_ERROR,
              })
            );
          }
        } catch (error) {
          console.error(error);
        }
      };
      getAllergies();
      fetchData();
    }, []);

    const handleCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { checked } = event.target;
      setIsRemovePartner(checked);
    };
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ maxHeight: "90%" }}
      >
        <DialogTitle>Update Patient Information</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <form onSubmit={onSubmit}>
              <div className="col-sm-12 p-2">
                <div className="row ms-1">
                  <div className="col-sm-4">
                    <div className="row m-2">
                      <FormControl>
                        <InputLabel id="title">Title</InputLabel>
                        <Select
                          id="title-select"
                          labelId="title"
                          label="Title"
                          name="title"
                          defaultValue={initialData.title}
                          onChange={handleSelectChange}
                        >
                          {Titles?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row m-2">
                      <FormControl>
                        <InputLabel id="gender">Gender</InputLabel>
                        <Select
                          id="gender-select"
                          labelId="gender"
                          label="Gender"
                          name="gender"
                          defaultValue={initialData.gender}
                          onChange={handleSelectChange}
                        >
                          {Genders?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="row p-2">
                      <MultiSelectInput
                        label={"Allergies"}
                        name={"allergies"}
                        id="allergies"
                        setValue={setValue}
                        value={value}
                        options={allergies}
                      />
                    </div>
                  </div>

                  <hr />
                  {PatientEditFormInputs.map((input) => (
                    <div className="col-sm-4" key={input.id}>
                      <div className="row m-2">
                        {input.type === "date" && initialData?.dateOfBirth ? (
                          <TextField
                            required={input.required}
                            id={input.id}
                            type={"date"}
                            label={input.label}
                            name={input.id}
                            defaultValue={parseDate(initialData?.dateOfBirth)}
                            onChange={handleChange}
                          />
                        ) : (
                          <TextField
                            required={input.required}
                            id={input.id}
                            type={input.type}
                            label={input.label}
                            name={input.id}
                            onChange={handleChange}
                            defaultValue={initialData[input.id] || ""}
                          />
                        )}
                      </div>
                    </div>
                  ))}

                  <hr />
                  <div className="col-sm-4">
                    <div className="row m-2">
                      <TextField
                        required={false}
                        id={"street1"}
                        label="Street"
                        name="address.street1"
                        onChange={handleChange}
                        defaultValue={initialData.address.street1 || ""}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row m-2">
                      <TextField
                        required={false}
                        id="city"
                        label="City"
                        name="address.city"
                        onChange={handleChange}
                        defaultValue={initialData.address.city || ""}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row m-2">
                      <TextField
                        required={false}
                        id="state"
                        label="State"
                        name="address.state"
                        onChange={handleChange}
                        defaultValue={initialData.address.state || ""}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row m-2">
                      <TextField
                        required={false}
                        id="country"
                        label="Country"
                        name="address.country"
                        onChange={handleChange}
                        defaultValue={initialData.address.country || ""}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row m-2">
                      <TextField
                        required={false}
                        id="postalCode"
                        label="Postal Code"
                        name="address.postalCode"
                        onChange={handleChange}
                        defaultValue={initialData.address.postalCode || ""}
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="col-sm-4 ">
                    <div className="row m-2">
                      {staffs && (
                        <FormControl>
                          <InputLabel id="staff">Select Staff</InputLabel>

                          <Select
                            id="staff-select"
                            label="Select Staff"
                            labelId="staff"
                            name="ownerStaff"
                            defaultValue={staffName}
                            onChange={handleSelectChange}
                          >
                            {staffs?.map((option: any, index) => (
                              <MenuItem
                                key={index}
                                value={
                                  option.title +
                                  " " +
                                  option.firstName +
                                  " " +
                                  option.lastName
                                }
                              >
                                {option.title +
                                  " " +
                                  option.firstName +
                                  " " +
                                  option.lastName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="m-2">
                    <BMICalculator
                      onCalculateBMI={function (bmi: number): void {
                        console.log(bmi);
                      }}
                      handleChange={handleBMIChange}
                    />
                  </div>
                  {/* <div className="col-sm-4 ">
                    <div className="row m-2">
                      <FormControlLabel
                        control={<Checkbox onChange={handleCheckboxChange} />}
                        label="Remove Partner"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <DialogActions>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                    setInitialData();
                  }}
                  autoFocus
                  variant="outlined"
                  color="error"
                >
                  Cancel
                </Button>{" "}
                <Button variant="contained" color="success" type="submit">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
);

export default PatientEditForm;
