import { AZURE_URL } from "../constants/env-constants";

export const getAllSites = async () => {
  try {
    const response = await AZURE_URL.get("/site/get/all");
    return [response.data, null];
  } catch (error: any) {
    return error.response;
  }
};

export const createNewSite = async (data: CreateSiteRequest) => {
  try {
    const response = await AZURE_URL.post("/site/create", data);
    return [response.data, null];
  } catch (error: any) {
    return error.response;
  }
};

export const updateSite = async (data: UpdateSiteRequest) => {
  try {
    const response = await AZURE_URL.put("/site/update", data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteSite = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/site/delete?publicId=${id}`);
    return [response, null];
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};
