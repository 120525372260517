import { AZURE_URL } from "../constants/env-constants";

export const getAllPathways = async () => {
  try {
    const response = await AZURE_URL.get("/pathway/get/all");
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const createPathway = async (pathway: CreatePathwayRequest) => {
  try {
    const response = await AZURE_URL.post("/pathway/create", pathway);
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const updatePathway = async (pathway: Pathway) => {
  try {
    const response = await AZURE_URL.put("/pathway/update", pathway);
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const deletePathway = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/pathway/delete?publicId=${id}`);
    return [response, null];
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};

export const getPathwayById = async (pathwayId: string) => {
  try {
    const response = await AZURE_URL.get(`/pathway/get?publicId=${pathwayId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};
