import { Box, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import TextInputFull from "../fromComponents/TextInputFull";
import PrimaryButton from "../buttons/PrimaryButton";
import { useSelector } from "react-redux";
import { FormDialogBox } from "../dialogBoxes/FormDialogBox";
import { NewPatientFormInputs } from "../../util/patient/patient.form.helper";
import { CreateOutlined, KeyboardOutlined } from "@mui/icons-material";
import { PregnancyConsultationForm } from "../../util/patient/consultationForm.helper";
import Notepad from "../tlDraw/CustomTlDraw";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCreateJsonFile from "../../hooks/useCreateJsonFile";
import {
  CONSULTATION,
  CONTAINER_NAME,
  FILE,
  FILE_NAME,
  NEED_TO_UPDATE,
} from "../../constants/Constants";
import { uploadFile } from "../../apis/files.api";
import Loading from "../loading/Loading";
import {
  getConsultationContainerName,
  getConsultationFileName,
} from "../../util/consultation/consultation.helper";
import { SAVED, SAVE_FAILED } from "../../constants/SnackbarMessages";
import { addConsultation } from "../../apis/consultation";
import useSnackbar from "../../hooks/useSnackbar";
import { useDispatch } from "react-redux";
import { setProgressNotes } from "../../redux/slice/progressNotesSlice";
interface ConsultationProps {
  defaultTemplateUrl: string;
}
const Consultation: React.FC<ConsultationProps> = ({ defaultTemplateUrl }) => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const { publicId } = patientObject.patient;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { register, handleSubmit, unregister, setValue, reset } =
    useForm<FieldValues>();
  const [patientForm, setPatientForm] = useState<InputFieldType[]>();
  const [isWriting, setIsWriting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const { createJsonFile, extractJson } = useCreateJsonFile();
  const { successSnackbar, errorSnackbar } = useSnackbar();
  const persistenceKey = publicId;
  const dispatch = useDispatch();
  const progressNotes: any = useSelector<ProgressNotesState>(
    (state) => state.progressNotes
  );
  const handleDownload = async () => {
    const persistedSnapshot = localStorage.getItem(persistenceKey);

    if (!persistedSnapshot) {
      const [data, error] = await extractJson(defaultTemplateUrl);
      if (data) {
        localStorage.setItem(persistenceKey, JSON.stringify(data));
        setIsLoading(false);
      } else {
        errorSnackbar("Failed to get consultation template.");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const consultationData = data as ConsultationPostRequest;
    setIsLoading(true);
    const persistedSnapshot = localStorage.getItem(persistenceKey);
    if (persistedSnapshot && isWriting) {
      const file = await createJsonFile(JSON.parse(persistedSnapshot));
      if (file) {
        const formData = new FormData();
        formData.append(FILE, file);
        formData.append(
          FILE_NAME,
          getConsultationFileName(publicId, CONSULTATION)
        );
        formData.append(CONTAINER_NAME, getConsultationContainerName(publicId));

        try {
          const [response, error] = await uploadFile(formData);
          if (response) {
            localStorage.removeItem(persistenceKey);
            setIsWriting(false);

            saveConsultation(consultationData, response);
          }
        } catch {}
        setIsLoading(false);
      }
    } else {
      saveConsultation(consultationData);
    }
  };

  const saveConsultation = async (
    data: ConsultationPostRequest,
    url?: string
  ) => {
    let obj: ConsultationPostRequest = {
      patientPublicId: patientObject?.patient?.publicId,
    };
    if (url) {
      obj = {
        ...obj,
        handwrittenNotes: [
          {
            url: url as string,
            label: CONSULTATION,
            height: 0,
          },
        ],
      };
    } else {
      obj = {
        ...obj,
        ...data,
      };
    }
    const [response, error] = await addConsultation(obj);
    if (response) {
      successSnackbar(CONSULTATION + SAVED);
      sessionStorage.setItem(NEED_TO_UPDATE, JSON.stringify(true));
      response.name = "Consultation";
      response.type = "Consultation";
      dispatch(setProgressNotes([...progressNotes.data, response]));
      // setTimeout(() => window.location.reload(), 1000);
      reset();
    } else {
      errorSnackbar(SAVE_FAILED + CONSULTATION);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setPatientForm(PregnancyConsultationForm);
    handleDownload();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-3">
            <div
              style={{
                display: "flex", // Add this line to make the content flexbox
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                size="large"
                onClick={() => setIsWriting((prev) => !prev)}
                aria-label="Toggle Writing Mode"
                color="primary"
              >
                {isWriting ? <KeyboardOutlined /> : <CreateOutlined />}
              </IconButton>
              <IconButton
                size="medium"
                aria-label="Toggle Writing Mode"
                color="primary"
              ></IconButton>{" "}
              {isWriting && (
                <IconButton
                  size="medium"
                  aria-label="Toggle Full Screen"
                  color="primary"
                  onClick={() => {
                    setIsFullScreen(!isFullScreen);
                    sessionStorage.setItem(
                      "isFullScreenActive",
                      JSON.stringify(true)
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={isFullScreen ? faCompress : faExpand}
                  />
                </IconButton>
              )}
            </div>
            {patientForm && (
              <form onSubmit={handleSubmit(onSubmit)}>
                {isWriting ? (
                  // <ConsultationNotepad
                  //   defaultSvg={defaultSvg}
                  //   patientForm={patientForm}
                  // />
                  <>
                    <div
                      style={{
                        background: "linear-gradient(#f5f5f5, #eaeaea)",

                        padding: "20px",
                        marginBottom: "20px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px",
                        position: isFullScreen ? "fixed" : "relative",
                        height: isFullScreen ? "" : "85vh",
                        width: isFullScreen ? "" : "80vw",
                        inset: isFullScreen ? "0" : "",
                        zIndex: isFullScreen ? "6" : "0",
                      }}
                    >
                      {isFullScreen && (
                        <div
                          style={{
                            display: "flex", // Add this line to make the content flexbox
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton
                            size="medium"
                            aria-label="Toggle Full Screen"
                            color="primary"
                            onClick={() => {
                              setIsFullScreen(!isFullScreen);
                              sessionStorage.setItem(
                                "isFullScreenActive",
                                JSON.stringify(true)
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faCompress} />
                          </IconButton>
                        </div>
                      )}
                      <Notepad persistenceKey={persistenceKey} />
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-sm-8 p-2">
                      <div className="row ms-1">
                        {patientForm?.map((item: InputFieldType) => {
                          return (
                            <TextInputFull
                              keyName={item.id}
                              register={register}
                              placeholder={item.placeholder}
                              id={item.id}
                              label={item.label}
                              type={item.type}
                              listName={item.id + "list"}
                              options={item.options}
                              required={item.required}
                              tag={item.tag}
                              maxLength={item.maxLength}
                              minRows={item.minRows}
                              checkboxOptions={item.checkboxOptions}
                              setValue={setValue}
                              unregister={unregister}
                              actionOn={item.actionOn}
                              radioOptions={item.radioOptions}
                              children={item.children}
                              disabled={item.disabled}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={1}>
                    <PrimaryButton label={"Save"} type={"submit"} />
                  </Grid>
                </Grid>
              </form>
            )}
            {isOpen && (
              <FormDialogBox
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={"Partner Information"}
                formFields={NewPatientFormInputs}
                width={"xl"}
              />
            )}
          </div>
        </>
      )}
    </Box>
  );
};

export default Consultation;
const P4PregnancyConsultation = {
  store: {
    "document:document": {
      gridSize: 10,
      name: "",
      meta: {},
      id: "document:document",
      typeName: "document",
    },
    "page:2PcVW5SiUALSaOA3S30TJ": {
      meta: {},
      id: "page:2PcVW5SiUALSaOA3S30TJ",
      name: "Page 1",
      index: "a1",
      typeName: "page",
    },
    "asset:-1338919844": {
      meta: {},
      type: "image",
      props: {
        name: "IMG_0021.png",
        src: "data:,",
        w: 749.6339677891654,
        h: 999.9999999999999,
        mimeType: "image/png",
        isAnimated: false,
      },
      id: "asset:-1338919844",
      typeName: "asset",
    },
    "shape:EbnKb8HCbCpoVwy3IoeJa": {
      x: 178.5442246032996,
      y: -908.5118405050378,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2450.342891754518,
        h: 4059.3740028509633,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a1",
      id: "shape:EbnKb8HCbCpoVwy3IoeJa",
      typeName: "shape",
    },
    "shape:k_1NJYUCfV6Qu_qLE296H": {
      x: 176.11551350986613,
      y: -913.2355007750945,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 208.3053604028372,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2",
      id: "shape:k_1NJYUCfV6Qu_qLE296H",
      typeName: "shape",
    },
    "shape:_VglIZszpvAJqAAxWYyaH": {
      x: 176.42950078393588,
      y: -706.2720877625851,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 207.19963605526104,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a3",
      id: "shape:_VglIZszpvAJqAAxWYyaH",
      typeName: "shape",
    },
    "shape:z-XCIz5A16kWka5v0Dtb9": {
      x: 176.4569718984169,
      y: -501.91282023238006,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 208.3053604028372,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2V",
      id: "shape:z-XCIz5A16kWka5v0Dtb9",
      typeName: "shape",
    },
    "shape:qSrTRF0xNO93e9uTKLYgX": {
      x: 176.77095917248673,
      y: -294.58083243734507,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 289.2964461378354,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4",
      id: "shape:qSrTRF0xNO93e9uTKLYgX",
      typeName: "shape",
    },
    "shape:-Cwffc_e1iob7ZaO_seKe": {
      x: 177.59449516687744,
      y: -3.4927884768831063,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 222.33309139940258,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a5",
      id: "shape:-Cwffc_e1iob7ZaO_seKe",
      typeName: "shape",
    },
    "shape:1HpHcYZ5uO4ilLCH5gelC": {
      x: 177.3160992509,
      y: 2713.3200005076537,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.6111669841043,
        h: 437.2877126330518,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4V",
      id: "shape:1HpHcYZ5uO4ilLCH5gelC",
      typeName: "shape",
    },
    "shape:9QSI0p1Mv8Q2i2f-aCY9n": {
      x: 177.30444862613004,
      y: -911.5835963638474,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 523.1568323394206,
        h: 4061.2761547445384,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a7",
      id: "shape:9QSI0p1Mv8Q2i2f-aCY9n",
      typeName: "shape",
    },
    "shape:Mgc6apq_jXdGKjHU_QFud": {
      x: 699.3961365609575,
      y: -911.583596363847,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 1929.2601396864752,
        h: 4061.904786107253,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a8",
      id: "shape:Mgc6apq_jXdGKjHU_QFud",
      typeName: "shape",
    },
    "shape:-D41zKShKqdqIMpI9WnFH": {
      x: 179.97144798776955,
      y: 1923.9301588205565,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2448.6810533392436,
        h: 788.16807921665,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4l",
      id: "shape:-D41zKShKqdqIMpI9WnFH",
      typeName: "shape",
    },
    "shape:piKJysMFJ46YvlbZronGe": {
      x: 1387.4286052088323,
      y: 1885.9318825077394,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 8,
        text: "",
        font: "draw",
        align: "start",
        autoSize: true,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aA",
      id: "shape:piKJysMFJ46YvlbZronGe",
      typeName: "shape",
    },
    "shape:dG462qJt5KCBwoNPdv_VD": {
      x: 211.62330250381544,
      y: -637.6796549290171,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "EDD",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aB",
      id: "shape:dG462qJt5KCBwoNPdv_VD",
      typeName: "shape",
    },
    "shape:SO2jH1MvT8MIX-a4akrzw": {
      x: 211.15180847572802,
      y: -188.1087044660918,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Medical Issues",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aC",
      id: "shape:SO2jH1MvT8MIX-a4akrzw",
      typeName: "shape",
    },
    "shape:bvrqvEZt6WgvE4hMgv2n9": {
      x: 212.5981353196457,
      y: -429.7650361778102,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Gestation",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aD",
      id: "shape:bvrqvEZt6WgvE4hMgv2n9",
      typeName: "shape",
    },
    "shape:r5oAjwfM6uSfhoelXtPZu": {
      x: 213.69899499834565,
      y: -852.53213691224,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 343.37092212625043,
        text: "Parity/Children",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aE",
      id: "shape:r5oAjwfM6uSfhoelXtPZu",
      typeName: "shape",
    },
    "shape:daNAZfxNctdb5kZZV2IPb": {
      x: 248.11931539500574,
      y: 2239.153081619434,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 355.5070794047069,
        text: "Consultation and Scan",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 0.9303543628087811,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aF",
      id: "shape:daNAZfxNctdb5kZZV2IPb",
      typeName: "shape",
    },
    "shape:NbURJh6DToXVIMmWf0KyK": {
      x: 241.45253102773904,
      y: 2861.247446565701,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 243.68324433628666,
        text: "Additional comments",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0560802651415109,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aG",
      id: "shape:NbURJh6DToXVIMmWf0KyK",
      typeName: "shape",
    },
    "shape:mUEr69JCEJbxfS57fAXB0": {
      x: 225.5840465491271,
      y: 67.10319348323503,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Medications",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCV",
      id: "shape:mUEr69JCEJbxfS57fAXB0",
      typeName: "shape",
    },
    "shape:MRDcwxXBkbB_K0FyfbHMf": {
      x: 176.03391241760755,
      y: 217.81832126878066,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 313.90563432143284,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2G",
      id: "shape:MRDcwxXBkbB_K0FyfbHMf",
      typeName: "shape",
    },
    "shape:o3ioUjFdW1tpZlqg9-HDE": {
      x: 176.37537080615812,
      y: 837.6607809004352,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 313.90563432143284,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2l",
      id: "shape:o3ioUjFdW1tpZlqg9-HDE",
      typeName: "shape",
    },
    "shape:n0f1gDNlilWsj9H9qN6PP": {
      x: 176.3478996916774,
      y: 529.7017087984257,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 312.239363698155,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a3V",
      id: "shape:n0f1gDNlilWsj9H9qN6PP",
      typeName: "shape",
    },
    "shape:QreRJw7Q1XQMw7dGxWYB7": {
      x: 176.68935808022798,
      y: 1150.0995919711731,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 435.9551009931502,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4G",
      id: "shape:QreRJw7Q1XQMw7dGxWYB7",
      typeName: "shape",
    },
    "shape:SNOqVVvLrVUqx9WDCLwhJ": {
      x: 177.512894074619,
      y: 1588.754540197601,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 335.04471489070704,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a5V",
      id: "shape:SNOqVVvLrVUqx9WDCLwhJ",
      typeName: "shape",
    },
    "shape:phkruREoIZnlz0XQ_Sjku": {
      x: 235.75209180502475,
      y: 333.5369873238782,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Obstetric history",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCl",
      id: "shape:phkruREoIZnlz0XQ_Sjku",
      typeName: "shape",
    },
    "shape:XSqxnyL2Q8O2ob9O4kbEX": {
      x: 237.0390105995673,
      y: 653.25753993265,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Medical History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCt",
      id: "shape:XSqxnyL2Q8O2ob9O4kbEX",
      typeName: "shape",
    },
    "shape:c_5ctf3xkiGM3QSIaHGuW": {
      x: 233.88536616343276,
      y: 937.4535866960026,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Surgical History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCx",
      id: "shape:c_5ctf3xkiGM3QSIaHGuW",
      typeName: "shape",
    },
    "shape:8TV3KdMvAmv2TRT3VeBtI": {
      x: 221.85059526594318,
      y: 1310.460898072903,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Gynae History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCz",
      id: "shape:8TV3KdMvAmv2TRT3VeBtI",
      typeName: "shape",
    },
    "shape:ERcCa9hSIOVsKtreDbcn3": {
      x: 236.45920375251808,
      y: 1696.7898991418356,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Family History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCzV",
      id: "shape:ERcCa9hSIOVsKtreDbcn3",
      typeName: "shape",
    },
  },
  schema: {
    schemaVersion: 1,
    storeVersion: 4,
    recordVersions: {
      asset: {
        version: 1,
        subTypeKey: "type",
        subTypeVersions: { image: 2, video: 2, bookmark: 0 },
      },
      camera: { version: 1 },
      document: { version: 2 },
      instance: { version: 20 },
      instance_page_state: { version: 4 },
      page: { version: 1 },
      shape: {
        version: 3,
        subTypeKey: "type",
        subTypeVersions: {
          group: 0,
          text: 1,
          bookmark: 1,
          draw: 1,
          geo: 7,
          note: 4,
          line: 1,
          frame: 0,
          arrow: 1,
          highlight: 0,
          embed: 4,
          image: 2,
          video: 1,
        },
      },
      instance_presence: { version: 5 },
      pointer: { version: 1 },
    },
  },
};
// template/p4fertilityConsultationTemplate.json
const P4FertilityConsultation = {
  store: {
    "document:document": {
      gridSize: 10,
      name: "",
      meta: {},
      id: "document:document",
      typeName: "document",
    },
    "page:2PcVW5SiUALSaOA3S30TJ": {
      meta: {},
      id: "page:2PcVW5SiUALSaOA3S30TJ",
      name: "Page 1",
      index: "a1",
      typeName: "page",
    },
    "asset:-1338919844": {
      meta: {},
      type: "image",
      props: {
        name: "IMG_0021.png",
        src: "data:,",
        w: 749.6339677891654,
        h: 999.9999999999999,
        mimeType: "image/png",
        isAnimated: false,
      },
      id: "asset:-1338919844",
      typeName: "asset",
    },
    "shape:EbnKb8HCbCpoVwy3IoeJa": {
      x: 174.950761376429,
      y: -913.9581762178805,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:EbnKb8HCbCpoVwy3IoeJa",
      type: "geo",
      props: {
        w: 1898.806252459536,
        h: 2492.966539446736,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a1",
      typeName: "shape",
    },
    "shape:k_1NJYUCfV6Qu_qLE296H": {
      x: 175.92172032957646,
      y: -913.2738764855214,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:k_1NJYUCfV6Qu_qLE296H",
      type: "geo",
      props: {
        w: 1898.349390273708,
        h: 181.71999080593267,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2",
      typeName: "shape",
    },
    "shape:_VglIZszpvAJqAAxWYyaH": {
      x: 176.1649050225172,
      y: -732.7245644703833,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:_VglIZszpvAJqAAxWYyaH",
      type: "geo",
      props: {
        w: 1897.3260809429805,
        h: 180.75538664074526,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a3",
      typeName: "shape",
    },
    "shape:z-XCIz5A16kWka5v0Dtb9": {
      x: 176.1861815362218,
      y: -554.4470388950492,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:z-XCIz5A16kWka5v0Dtb9",
      type: "geo",
      props: {
        w: 1898.349390273708,
        h: 181.71999080593267,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2V",
      typeName: "shape",
    },
    "shape:qSrTRF0xNO93e9uTKLYgX": {
      x: 176.42936622916258,
      y: -373.5761921581817,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:qSrTRF0xNO93e9uTKLYgX",
      type: "geo",
      props: {
        w: 1897.3260809429805,
        h: 181.39845608420347,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4",
      typeName: "shape",
    },
    "shape:-Cwffc_e1iob7ZaO_seKe": {
      x: 177.06719892004125,
      y: -191.05434488377523,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:-Cwffc_e1iob7ZaO_seKe",
      type: "geo",
      props: {
        w: 1897.3260809429805,
        h: 181.39845608420347,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a5",
      typeName: "shape",
    },
    "shape:1HpHcYZ5uO4ilLCH5gelC": {
      x: 175.3273181893146,
      y: 671.5024382347974,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:1HpHcYZ5uO4ilLCH5gelC",
      type: "geo",
      props: {
        w: 1899.7326046355854,
        h: 727.832773919498,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4V",
      typeName: "shape",
    },
    "shape:U2GQ2AvEYRmn6TmhwIPcu": {
      x: 177.03756251453214,
      y: 1400.8419029729896,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:U2GQ2AvEYRmn6TmhwIPcu",
      type: "geo",
      props: {
        w: 1897.3260809429805,
        h: 181.39845608420347,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a6",
      typeName: "shape",
    },
    "shape:9QSI0p1Mv8Q2i2f-aCY9n": {
      x: 176.7751152558393,
      y: -911.7123085607725,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:9QSI0p1Mv8Q2i2f-aCY9n",
      type: "geo",
      props: {
        w: 407.0405293137271,
        h: 2489.510610831726,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a7",
      typeName: "shape",
    },
    "shape:Mgc6apq_jXdGKjHU_QFud": {
      x: 581.4089399382423,
      y: -911.7123085607723,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:Mgc6apq_jXdGKjHU_QFud",
      type: "geo",
      props: {
        w: 747.1156412547639,
        h: 2492.4019773033424,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a8",
      typeName: "shape",
    },
    "shape:aOoGzj4aWsSaBn_ewkyUi": {
      x: 1330.3293032556312,
      y: -910.346428904543,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:aOoGzj4aWsSaBn_ewkyUi",
      type: "geo",
      props: {
        w: 747.1156412547639,
        h: 2492.4019773033424,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a9",
      typeName: "shape",
    },
    "shape:-D41zKShKqdqIMpI9WnFH": {
      x: 175.58583571204667,
      y: -10.315251351364509,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:-D41zKShKqdqIMpI9WnFH",
      type: "geo",
      props: {
        w: 1897.4620845378665,
        h: 680.9203994350842,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4l",
      typeName: "shape",
    },
    "shape:piKJysMFJ46YvlbZronGe": {
      x: 1387.4286052088323,
      y: 1885.9318825077394,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:piKJysMFJ46YvlbZronGe",
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 8,
        text: "",
        font: "draw",
        align: "start",
        autoSize: true,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aA",
      typeName: "shape",
    },
    "shape:dG462qJt5KCBwoNPdv_VD": {
      x: 202.98418533358813,
      y: -675.7082470308021,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:dG462qJt5KCBwoNPdv_VD",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Occupation",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aB",
      typeName: "shape",
    },
    "shape:SO2jH1MvT8MIX-a4akrzw": {
      x: 211.75924306309162,
      y: -498.8645310504048,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:SO2jH1MvT8MIX-a4akrzw",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Smoking",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aC",
      typeName: "shape",
    },
    "shape:bvrqvEZt6WgvE4hMgv2n9": {
      x: 204.08372256186044,
      y: -313.7955259546402,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:bvrqvEZt6WgvE4hMgv2n9",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Trying Since",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aD",
      typeName: "shape",
    },
    "shape:r5oAjwfM6uSfhoelXtPZu": {
      x: 208.0242370264467,
      y: -144.28748337520483,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:r5oAjwfM6uSfhoelXtPZu",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 343.37092212625043,
        text: "Parity/Children",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aE",
      typeName: "shape",
    },
    "shape:daNAZfxNctdb5kZZV2IPb": {
      x: 204.25132569137128,
      y: 264.4437926972014,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:daNAZfxNctdb5kZZV2IPb",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 369.8260209011635,
        text: "Medical/Surgical History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aF",
      typeName: "shape",
    },
    "shape:NbURJh6DToXVIMmWf0KyK": {
      x: 207.40251562081767,
      y: 989.5428423177566,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:NbURJh6DToXVIMmWf0KyK",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 369.8260209011635,
        text: "Medications",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aG",
      typeName: "shape",
    },
    "shape:iQWjjCsGixUVCyr4FFRdH": {
      x: 215.26214093741078,
      y: 1450.969510258109,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:iQWjjCsGixUVCyr4FFRdH",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 369.8260209011635,
        text: "Genetic",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aH",
      typeName: "shape",
    },
    "shape:CcSbhhfexiYnBkFOBTXP1": {
      x: 676.6888088777641,
      y: -853.8096117500834,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:CcSbhhfexiYnBkFOBTXP1",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 598.1851371777668,
        text: "Female/Who intend to carry",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aGV",
      typeName: "shape",
    },
    "shape:kXDIfMJVmuxqu13QEB5Sq": {
      x: 1426.1270076699245,
      y: -853.8096117500835,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:kXDIfMJVmuxqu13QEB5Sq",
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 454.5778578697998,
        text: "Partner Male/Female",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aGl",
      typeName: "shape",
    },
  },
  schema: {
    schemaVersion: 1,
    storeVersion: 4,
    recordVersions: {
      asset: {
        version: 1,
        subTypeKey: "type",
        subTypeVersions: { image: 2, video: 2, bookmark: 0 },
      },
      camera: { version: 1 },
      document: { version: 2 },
      instance: { version: 20 },
      instance_page_state: { version: 4 },
      page: { version: 1 },
      shape: {
        version: 3,
        subTypeKey: "type",
        subTypeVersions: {
          group: 0,
          text: 1,
          bookmark: 1,
          draw: 1,
          geo: 7,
          note: 4,
          line: 1,
          frame: 0,
          arrow: 1,
          highlight: 0,
          embed: 4,
          image: 2,
          video: 1,
        },
      },
      instance_presence: { version: 5 },
      pointer: { version: 1 },
    },
  },
};
