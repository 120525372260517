import "./App.css";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RoutesPath from "./routes/Routes";
import {
  getClientBackgroundColor,
  getClientBrandColorDark,
  getClientBrandColorLight,
} from "./constants/BrandConstants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { lime, purple } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { useGetCurrentRoute } from "./hooks/useLocation";

const theme = createTheme({
  palette: {
    primary: {
      main: getClientBrandColorDark(),
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: getClientBrandColorLight(),
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
});
function App() {
  const currentRoute = useGetCurrentRoute();

  const getBackgroundColorForRoute = (route: string) => {
    switch (route) {
      case "/login":
        return "#f5f5f5";
      default:
        return "#f4ccee";
    }
  };
  const divStyle = {
    "--app-bg-color": getBackgroundColorForRoute(currentRoute),
  } as React.CSSProperties;

  return (
    <ThemeProvider theme={theme}>
      <div style={divStyle} className="app-bg-color">
        <RoutesPath />
      </div>
    </ThemeProvider>
  );
}
export default App;
