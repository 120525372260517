import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Card, Chip, Grid, Typography } from "@mui/material";
import {
  getArchivedEventsByPathwayId,
  getArchivedEventsByPatientId,
  getEventByPatientID,
  getEventsByPathwayId,
  updateEventStage,
} from "../../apis/events";
import { getAllPathways } from "../../apis/pathway";
import ComponentLoading from "../loading/ComponentLoading";
import StageCard from "./StageCard";
import { useDispatch } from "react-redux";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import PrimaryButton from "../buttons/PrimaryButton";
import ArchivedEvents from "./ArchivedEvents";
import { getClientBackgroundColor } from "../../constants/BrandConstants";
interface KanbanBoardProps {
  patientId?: string;
}

const ClinicBoard: React.FC<KanbanBoardProps> = ({ patientId }) => {
  const ColumnContainer = styled(Box)(({ theme }) => ({
    // width: "33.33%",
    minWidth: "320px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #ccc ",
    backgroundColor: getClientBackgroundColor(),
    borderRadius: "1rem",
    margin: "0.5rem",
    minHeight: patientId ? 400 : 1000,
    maxHeight: patientId ? 400 : 1000,
    overflow: "auto",
  }));

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [pathways, setPathways] = useState<PathwayLite[]>();
  const [events, setEvents] = useState<BoardEvent[]>();
  const [selectedPathwayId, setSelectedPathwayId] = useState<string>();
  const [selectedPathway, setSelectedPathway] = useState<PathwayLite>();
  const [eventToUpdateId, setEventToUpdateId] = useState<string>();
  const [showBoard, setShowBoard] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [archivedEvents, setArchivedEvents] = useState<EventLite[]>([]);

  const toggleSortOrder = () => {
    setIsUpdating(true);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setIsUpdating(false);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    event: BoardEvent
  ) => {
    setEventToUpdateId(event.eventPublicId);
    e.dataTransfer.setData("text/plain", event.eventPublicId);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const setEventsHandler = (
    prevEvents: BoardEvent[] | undefined,
    stage: StageLite
  ): BoardEvent[] | undefined => {
    return prevEvents?.map((event: BoardEvent) => {
      if (event.eventPublicId === eventToUpdateId) {
        return {
          ...event,
          currentStage: stage,
        };
      }
      return event;
    });
  };

  const removeArchivedEvent = (archivedEvent: BoardEvent) => {
    setEvents((prevEvents) =>
      prevEvents?.filter(
        (event: BoardEvent) =>
          event.eventPublicId !== archivedEvent.eventPublicId
      )
    );
  };

  const handleDrop = async (
    e: React.DragEvent<HTMLDivElement>,
    stage: StageLite
  ) => {
    e.preventDefault();
    if (eventToUpdateId) {
      let updateStageObj: UpdateEventRequest = {
        publicId: eventToUpdateId,
        currentStagePublicId: stage.publicId,
      };
      // if (prevStageId !== updateStageObj.currentStagePublicId) {
      // setIsUpdating(true);
      // setPrevStageId(updateStageObj.currentStagePublicId);
      const [response, error] = await updateEventStage(updateStageObj);
      if (response) {
        setEvents((prevEvents) => setEventsHandler(prevEvents, stage));

        dispatch(
          activateSnackbar({
            active: true,
            message: `Updated to '${stage.name}' stage`,
            variant: SNACKBAR_SUCCESS,
          })
        );
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: "Failed to update to new stage",
            variant: SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  const getEvents = async (pathway: PathwayLite) => {
    setIsUpdating(true);
    setSelectedPathwayId(pathway.publicId);
    setSelectedPathway(pathway);
    let response, error;

    if (patientId) {
      [response, error] = await getEventByPatientID(patientId);
    } else {
      [response, error] = await getEventsByPathwayId(pathway.publicId);
    }
    if (response) {
      let data: BoardEvent[] = [];

      response.forEach((element: EventLite) => {
        data.push({
          name: element.service.name,
          patient: element.patient,
          servicePublicId: element.service.publicId,
          staff: element.staff,
          // stages: element.pathway.pathwayStages,
          booking: element.booking,
          currentStage: element.currentStage,
          eventPublicId: element.publicId,
          assignee: element.assignee,
          assigneeTeam: element.assigneeTeam,
          whenLastStageUpdated: element.whenLastStageUpdated,
        });
      });
      // if (data.length === 0) {
      //   setShowBoard(false);
      // } else {
      //   setShowBoard(true);
      // }
      setEvents(data);
    } else {
      console.log(error);
    }

    setIsUpdating(false);
  };
  const getPathways = async () => {
    const [response, error] = await getAllPathways();
    setSelectedPathwayId(response[1].publicId);
    setPathways(response);
    getEvents(response[1]);
  };
  const getArchivedEvents = async (pathway: PathwayLite) => {
    setIsUpdating(true);
    setSelectedPathwayId(pathway.publicId);
    setSelectedPathway(pathway);
    setArchivedEvents([]);

    if (pathway.publicId) {
      try {
        let response, error;
        if (patientId) {
          [response, error] = await getArchivedEventsByPatientId(patientId);
          if (response) {
            setShowArchived(true);
            setArchivedEvents(response);
            setIsUpdating(false);
          } else {
            setShowArchived(true);
            setArchivedEvents([]);
            setIsUpdating(false);
          }
        } else {
          [response, error] = await getArchivedEventsByPathwayId(
            pathway.publicId
          );
          if (response) {
            setShowArchived(true);
            setArchivedEvents(response);
            setIsUpdating(false);
          } else {
            setShowArchived(true);
            setArchivedEvents([]);
            setIsUpdating(false);
          }
        }
      } catch {
        setIsUpdating(false);
      }
    }
  };
  useEffect(() => {
    getPathways();
  }, []);

  useEffect(() => {
    console.log("first");
    if (selectedPathway && !showArchived) getEvents(selectedPathway);
  }, [selectedPathway]);

  return (
    <Grid item xs={12} sm={12} md={12} padding={patientId ? 0 : 2}>
      <Card sx={{ p: 2, borderRadius: 5, minHeight: patientId ? 250 : 800 }}>
        {!isUpdating ? (
          <>
            <Grid
              container
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
              p={1}
            >
              {showArchived && selectedPathway ? (
                <>
                  <Grid item>
                    <Grid container spacing={1} overflow={"auto"}>
                      {pathways?.map((option: PathwayLite) => (
                        <Grid item key={option.publicId}>
                          <Chip
                            label={option.name}
                            color="primary"
                            onClick={() => getArchivedEvents(option)}
                            variant={
                              selectedPathwayId === option.publicId
                                ? "filled"
                                : "outlined"
                            }
                            sx={{ fontWeight: "bold" }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      label={"Clinic Board Events"}
                      type={"button"}
                      onClick={() => {
                        console.log("button");
                        setShowArchived(false);
                        getEvents(selectedPathway);
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Grid container spacing={1} overflow={"auto"}>
                      {pathways?.map((option: PathwayLite) => (
                        <Grid item key={option.publicId}>
                          <Chip
                            label={option.name}
                            color="primary"
                            onClick={() => getEvents(option)}
                            variant={
                              selectedPathwayId === option.publicId
                                ? "filled"
                                : "outlined"
                            }
                            sx={{ fontWeight: "bold" }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    {selectedPathway && (
                      <PrimaryButton
                        label={"Archived Events"}
                        type={"button"}
                        onClick={() => getArchivedEvents(selectedPathway)}
                      />
                    )}
                  </Grid>
                </>
              )}
            </Grid>

            {showArchived ? (
              <ArchivedEvents data={archivedEvents} />
            ) : showBoard ? (
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="row"
                minHeight="100%"
                overflow="auto"
              >
                {selectedPathway?.pathwayStages?.map((pathwayStage, index) => (
                  <ColumnContainer
                    key={index}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, pathwayStage)}
                  >
                    <Box
                      sx={{
                        bgcolor: "#fcfcfc",
                        width: "100%",
                        position: "sticky",
                        padding: 1,
                        mb: 1,
                        top: 0,
                        cursor: "pointer",
                      }}
                      onClick={toggleSortOrder}
                    >
                      <Typography variant="body2" align="center">
                        {pathwayStage.name} {sortOrder === "asc" ? "▲" : "▼"}
                      </Typography>
                    </Box>

                    {events
                      ?.filter(
                        (event: any) =>
                          pathwayStage.publicId === event?.currentStage.publicId
                      )
                      .sort((a: any, b: any) => {
                        const sortOrderMultiplier =
                          sortOrder === "asc" ? 1 : -1;
                        return (
                          sortOrderMultiplier *
                          (new Date(a.whenLastStageUpdated).getTime() -
                            new Date(b.whenLastStageUpdated).getTime())
                        );
                      })
                      .map((event: any) => (
                        <StageCard
                          key={event.eventPublicId}
                          task={event}
                          onDragStart={handleDragStart}
                          patientId={patientId}
                          removeArchivedEvent={removeArchivedEvent}
                        />
                      ))}
                  </ColumnContainer>
                ))}
              </Box>
            ) : (
              <>Data not available</>
            )}
          </>
        ) : (
          <ComponentLoading />
        )}
      </Card>
    </Grid>
  );
};
export default React.memo(ClinicBoard);
