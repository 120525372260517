import jsPDF from "jspdf";
import { addFooter, centerPosition } from "../pdf.util";

import {
  ADDRESS,
  ALIGN_CENTER,
  ALIGN_RIGHT,
  ALLERGIES,
  DATE,
  DATE_OF_BIRTH,
  DIRECTIONS,
  DURATION,
  GMC_NO,
  MEDICINE,
  NAME,
  PATIENT_ID,
  PRIVATE_PRESCRIPTION,
} from "../../constants/jspdf.constants";
import { formatDate } from "../data.util";
import {
  BRAND_NAME,
  getClientDocumentLogo,
  getClientLogo,
} from "../../constants/BrandConstants";

let globalPosition = 10;
const marginLeft = 15; //
const spaceBetween = 6;
const textFontPoint = 11;
const precautionFontPoint = 9;
const reportTitlePoint = 15; //
const commonSpaceBetween = 10; //
const clinicAddress =
  "Priory Hospital Priory Road,\n Edgbaston, Birmingham  B5 7UG\n ";
const email = "secretary@p4fertility.co.uk";
const phone = "0121 314 5503";
const precaution =
  "Please confirm that you do not have any known allergies to the above medication(s). Stop the medication(s) if any allergic reaction occur such as rash, breathlessness, or itchings; seek immediate medical help. Please contact our team for any queries.";
const logo = getClientDocumentLogo();
export const createPrescriptionPDF = (prescription: Prescription) => {
  let doc = new jsPDF({ compress: true }).setProperties({
    title: BRAND_NAME,
  });
  doc = addHeader(doc, clinicAddress, email, phone, prescription.staff);
  doc = addFooter(doc);
  addPatientInfo(doc, prescription.patient);
  addTitle(doc, prescription);
  addPrescriptionData(doc, prescription.prescriptionRows);
  addSignature(doc, prescription.staff);
  addPrecaution(doc);
  doc.save(
    `${prescription.patient.title} ${prescription.patient.firstName} ${
      prescription.patient.lastName
    }_${formatDate(prescription.patient.dateOfBirth)}.pdf`
  );
  globalPosition = 10;
  // }
};

const addHeader = (
  doc: any,
  clinicAddress: string,
  email: string,
  phone: string,
  staff: StaffInformation
) => {
  doc.addImage(logo, "PNG", 5, 0, 70, 35);
  doc
    .setFontSize(10)
    .text(
      `${staff.title} ${staff.firstName} ${staff.lastName}`,
      195,
      14,
      null,
      null,
      ALIGN_RIGHT
    )
    .text("Consultant (Pregnancy)", 195, 20, null, null, ALIGN_RIGHT)
    .text(
      "81 Harborne Rd, Birmingham B15 3HG",
      195,
      26,
      null,
      null,
      ALIGN_RIGHT
    );
  generateHr(doc, 28);
  return doc;
};

const addPatientInfo = (doc: any, patientData: PatientInformationLite) => {
  getGlobalPosition(25);

  doc
    .setFontSize(textFontPoint)
    .text(PATIENT_ID + patientData.publicId, marginLeft, globalPosition)
    .text(
      `${NAME} ${patientData.title ?? ""} ${patientData.firstName ?? ""} ${
        patientData.lastName ?? ""
      }`,
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text(
      DATE_OF_BIRTH + formatDate(patientData.dateOfBirth),

      marginLeft + 100,
      globalPosition
    )

    .text(
      ADDRESS +
        `${patientData.address?.street1}, ${patientData.address.city}, ${patientData.address.postalCode} `,
      marginLeft,
      getGlobalPosition(spaceBetween)
    )
    .text(ALLERGIES, marginLeft + 100, globalPosition);

  generateHr(doc, getGlobalPosition(spaceBetween));
};

const addTitle = (doc: any, prescription: Prescription) => {
  doc
    .text(
      DATE + formatDate(prescription.whenCreated.toString()),
      marginLeft + 150,
      getGlobalPosition(7)
    )
    .setFontSize(reportTitlePoint)
    .text(
      PRIVATE_PRESCRIPTION,
      centerPosition,
      getGlobalPosition(10),
      null,
      null,
      ALIGN_CENTER
    );

  return getGlobalPosition(0);
};

const addPrescriptionData = (doc: any, prescriptionData: Medicine[]) => {
  doc
    .setFontSize(textFontPoint)
    .text(MEDICINE, marginLeft + 5, getGlobalPosition(commonSpaceBetween * 2))
    .text(DIRECTIONS, marginLeft + 80, globalPosition)
    .text(DURATION, marginLeft + 140, globalPosition);
  prescriptionData.forEach((element, index) => {
    doc
      .text(
        `${index + 1}. ${element.medicine}`,
        marginLeft,
        getGlobalPosition(commonSpaceBetween)
      )
      .text(element.directions, marginLeft + 80, globalPosition)
      .text(element.duration, marginLeft + 140, globalPosition);
  });
};

const addSignature = (doc: any, staff: StaffInformation) => {
  doc
    .text(`${staff.signature}`, marginLeft, 250)
    .text(`${GMC_NO}${staff.gmcNumber || ""}`, marginLeft, 256);
};

const addPrecaution = (doc: any) => {
  doc
    .setFontSize(precautionFontPoint)
    .text(precaution, marginLeft, 270, { maxWidth: 175 }, null)
    .setFontSize(textFontPoint);
};

const generateHr = (doc: any, y: number) => {
  doc.line(marginLeft, y, 195, y, "S");
};

const getGlobalPosition = (y: number) => {
  return (globalPosition += y);
};
