export const calculateBMI = (
  weight: number,
  weightUnit: string,
  height: number,
  heightUnit: string
) => {
  const weightValue = weight;
  const heightValue = height;
  let weightMultiplier = 1;
  let heightMultiplier = 1;

  if (
    isNaN(weightValue) ||
    isNaN(heightValue) ||
    weightValue <= 0 ||
    heightValue <= 0
  ) {
    return;
  }

  let bmiValue;

  if (weightUnit === "kg") {
    weightMultiplier = 1;
  } else if (weightUnit === "lbs") {
    weightMultiplier = 0.45359237; // 1 lb = 0.45359237 kg
  } else if (weightUnit === "stone") {
    weightMultiplier = 6.35029318; // 1 stone = 6.35029318 kg
  }

  if (heightUnit === "cm") {
    heightMultiplier = 0.01; // Convert cm to meters
  } else if (heightUnit === "in") {
    heightMultiplier = 0.0254; // 1 inch = 0.0254 meters
  } else if (heightUnit === "ft") {
    heightMultiplier = 0.3048; // 1 foot = 0.3048 meters
  }

  bmiValue =
    weightValue /
    (heightValue * heightMultiplier * heightValue * heightMultiplier);
  return `${bmiValue.toFixed(
    2
  )}, Weight ${weight} ${weightUnit.toUpperCase()}, Height ${height} ${heightUnit.toUpperCase()}`;
};
