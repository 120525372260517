import { AZURE_URL } from "../constants/env-constants";

export const getServicesByType = async (typeOfTreatment: string) => {
  try {
    const response = await AZURE_URL.get(
      `/service/get?type=${typeOfTreatment}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const getAllServices = async () => {
  try {
    const response = await AZURE_URL.get("/service/get/all");
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const deleteService = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/service/delete?publicId=${id}`);
    return [response, null];
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};

export const createService = async (service: CreateServiceRequest) => {
  try {
    const response = await AZURE_URL.post("/service/create", service);
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};
