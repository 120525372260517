import { PLACEHOLDER_VALUE } from "../../constants/Constants";

export const PregnancyConsultationForm: InputFieldType[] = [
  {
    label: "Parity",
    type: "text",
    id: "parity",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "EDD",
    type: "date",
    id: "edd",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },

  {
    label: "Gestation",
    type: "text",
    tag: "textarea",
    id: "gestation",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Medical Issues",
    type: "text",
    tag: "textarea",
    id: "medicalIssues",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 512,
    minRows: 2,
  },
  {
    label: "Medications",
    type: "text",
    id: "medications",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
  },
  {
    label: "Obstetric History",
    type: "text",
    tag: "textarea",
    id: "obstetricHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 2,
  },

  {
    label: "Medical History",
    type: "text",
    tag: "textarea",
    id: "medicalHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 2,
  },
  {
    label: "Surgical History",
    type: "text",
    tag: "textarea",
    id: "surgicalHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 2,
  },
  {
    label: "Gynae History",
    type: "text",
    tag: "textarea",
    id: "gynaeHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 2,
  },
  {
    label: "Family History",
    type: "text",
    tag: "textarea",
    id: "familyHistory",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 2,
  },
  {
    label: "Consultation & Scan",
    type: "text",
    tag: "textarea",
    id: "consultation",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    maxLength: 12,
    minRows: 6,
  },

  {
    label: "Additional Comments",
    type: "text",
    id: "additionalComments",
    placeholder: PLACEHOLDER_VALUE,
    required: false,
    tag: "textarea",
    maxLength: 512,
    minRows: 2,
  },
];
