import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import amh from "../../assets/resources/amh-image.jpg";
import menstrualCycleDiagram from "../../assets/resources/MenstrualCycle-diagram.jpg";
import hpo from "../../assets/resources/hpo_axis.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import TernaryButton from "../buttons/TernaryButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFileUpload } from "../../hooks/useFileUpload";
import FileUpload from "../files/FileUpload";
import { useDispatch } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import { createDocument, getDocuments } from "../../apis/documents";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { NOTEPAD_IMAGES_CONTAINER } from "../../constants/ContainerConstants";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../constants/Constants";
import { useDownloadFile } from "../../hooks/useDownloadFile";

// Define prop types for ImageUploadModal
interface NotepadImageModalProps {
  open: boolean;
  onClose: () => void;
  setSelectedImage: React.Dispatch<
    React.SetStateAction<NotepadSelectedImages[]>
  >;
}

const NotepadImageModal: React.FC<NotepadImageModalProps> = ({
  open,
  onClose,
  setSelectedImage,
}) => {
  const dispatch = useDispatch();
  // const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [documents, setDocuments] = useState<string[]>([]);
  const { getFileSrc } = useDownloadFile();
  const [documentUrls, setDocumentUrls] = useState<
    {
      name: string;
      url: string;
    }[]
  >([]);

  const getAllPatientDocuments = async () => {
    try {
      const [response, error] = await getDocuments("P105130");
      setDocuments([]);

      if (response) {
        const filteredItems = response.filter(
          (item: any) => item.type === NOTEPAD_IMAGES_CONTAINER
        );

        await Promise.all(
          filteredItems.map(async (item: any) => {
            const [url] = await getFileSrc(item.url);
            if (url) {
              setDocuments((prev) => [...prev, url]);
            }
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addDocumentsToPatient = async () => {
    try {
      for (const document of documentUrls) {
        const upload = {
          name: document.name,
          type: NOTEPAD_IMAGES_CONTAINER,
          url: document.url,
        };
        dispatch(loading());
        const response = await createDocument(upload);

        if (response) {
          dispatch(
            activateSnackbar({
              active: true,
              message: "Image uploaded." + upload.name,
              variant: SNACKBAR_SUCCESS,
            })
          );

          getAllPatientDocuments();
          setDocumentUrls([]);
        } else {
          dispatch(
            activateSnackbar({
              active: true,
              message: "Failed" + upload.name,
              variant: SNACKBAR_ERROR,
            })
          );
        }
        dispatch(doneLoading());
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePenSizeChange = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      setSelectedSize(value);
    }
  };

  const [selectedSize, setSelectedSize] = useState<number>(100);

  useEffect(() => {
    if (documentUrls.length > 0) {
      addDocumentsToPatient();
    }
  }, [documentUrls]);

  useEffect(() => {
    getAllPatientDocuments();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"xl"}
      sx={{ height: "90%" }}
    >
      <DialogTitle>
        Notepad Images
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 15, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Select an image to upload or use an existing one.
        </DialogContentText>
        <hr />
        <Grid item className="toolbarPenSize" sx={{ width: 200 }}>
          <IconButton
            aria-label="Toggle Eraser"
            color={"primary"}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </IconButton>

          <Slider
            value={selectedSize}
            onChange={handlePenSizeChange}
            min={100}
            max={600}
          />
        </Grid>

        <Grid container>
          {documents.map((item, index) => (
            <Grid
              item
              sx={{ border: "1px solid" }}
              m={1}
              key={index}
              onClick={() => {
                setSelectedImage((prev) => [...prev, { src: item }]);
                onClose();
              }}
            >
              <img alt="test" src={item} width={selectedSize} />
            </Grid>
          ))}
        </Grid>

        <Grid container sm={6}>
          <FileUpload
            uploadButtonText="upload"
            setFileUrls={setDocumentUrls}
            containerName={NOTEPAD_IMAGES_CONTAINER}
            setIsUploadDocuments={(value) => {
              console.log("value", value);
            }}
            patientId={"T00000"}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NotepadImageModal;
