import {
  Tldraw,
  createTLStore,
  defaultShapeUtils,
  throttle,
} from "@tldraw/tldraw";
import { relative } from "path";
import React, { useEffect, useState } from "react";
import useCreateJsonFile from "../../hooks/useCreateJsonFile";
interface ShowTldrawData {
  url: string;
}
const ShowTldrawData: React.FC<ShowTldrawData> = ({ url }) => {
  const [store] = useState(() =>
    createTLStore({ shapeUtils: defaultShapeUtils })
  );
  const { createJsonFile, extractJson } = useCreateJsonFile();
  const handleDownload = async () => {
    // const persistedSnapshot = localStorage.getItem(persistenceKey);

    const [data, error] = await extractJson(url);
    if (data) {
      localStorage.setItem("persistenceKey", JSON.stringify(data));
      const persistedSnapshot = localStorage.getItem("persistenceKey");

      if (persistedSnapshot) {
        const snapshot = JSON.parse(JSON.stringify(data));
        store.loadSnapshot(snapshot);
      }
    }
  };
  useEffect(() => {
    handleDownload();
  }, []);

  // Disable the Tldraw toolbar and other UI elements.
  //   store.toolbar = false;
  //   store.ui.inspector = false;
  //   store.ui.statusbar = false;

  return (
    <div
      className="text-center"
      style={{
        width: "80%",
        margin: "0 auto",
        position: "relative",
        height: 700,
        zIndex: 0,
        border: "2px solid ",
      }}
    >
      <Tldraw store={store} hideUi />
    </div>
  );
};

export default ShowTldrawData;

const P4PregnancyConsultation = {
  store: {
    "document:document": {
      gridSize: 10,
      name: "",
      meta: {},
      id: "document:document",
      typeName: "document",
    },
    "page:2PcVW5SiUALSaOA3S30TJ": {
      meta: {},
      id: "page:2PcVW5SiUALSaOA3S30TJ",
      name: "Page 1",
      index: "a1",
      typeName: "page",
    },
    "asset:-1338919844": {
      meta: {},
      type: "image",
      props: {
        name: "IMG_0021.png",
        src: "data:,",
        w: 749.6339677891654,
        h: 999.9999999999999,
        mimeType: "image/png",
        isAnimated: false,
      },
      id: "asset:-1338919844",
      typeName: "asset",
    },
    "shape:EbnKb8HCbCpoVwy3IoeJa": {
      x: 178.5442246032996,
      y: -908.5118405050378,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2450.342891754518,
        h: 4059.3740028509633,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "middle",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a1",
      id: "shape:EbnKb8HCbCpoVwy3IoeJa",
      typeName: "shape",
    },
    "shape:k_1NJYUCfV6Qu_qLE296H": {
      x: 176.11551350986613,
      y: -913.2355007750945,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 208.3053604028372,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2",
      id: "shape:k_1NJYUCfV6Qu_qLE296H",
      typeName: "shape",
    },
    "shape:_VglIZszpvAJqAAxWYyaH": {
      x: 176.42950078393588,
      y: -706.2720877625851,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 207.19963605526104,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a3",
      id: "shape:_VglIZszpvAJqAAxWYyaH",
      typeName: "shape",
    },
    "shape:z-XCIz5A16kWka5v0Dtb9": {
      x: 176.4569718984169,
      y: -501.91282023238006,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 208.3053604028372,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2V",
      id: "shape:z-XCIz5A16kWka5v0Dtb9",
      typeName: "shape",
    },
    "shape:qSrTRF0xNO93e9uTKLYgX": {
      x: 176.77095917248673,
      y: -294.58083243734507,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 289.2964461378354,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4",
      id: "shape:qSrTRF0xNO93e9uTKLYgX",
      typeName: "shape",
    },
    "shape:-Cwffc_e1iob7ZaO_seKe": {
      x: 177.59449516687744,
      y: -3.4927884768831063,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 222.33309139940258,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a5",
      id: "shape:-Cwffc_e1iob7ZaO_seKe",
      typeName: "shape",
    },
    "shape:1HpHcYZ5uO4ilLCH5gelC": {
      x: 177.3160992509,
      y: 2713.3200005076537,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.6111669841043,
        h: 437.2877126330518,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4V",
      id: "shape:1HpHcYZ5uO4ilLCH5gelC",
      typeName: "shape",
    },
    "shape:9QSI0p1Mv8Q2i2f-aCY9n": {
      x: 177.30444862613004,
      y: -911.5835963638474,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 523.1568323394206,
        h: 4061.2761547445384,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a7",
      id: "shape:9QSI0p1Mv8Q2i2f-aCY9n",
      typeName: "shape",
    },
    "shape:Mgc6apq_jXdGKjHU_QFud": {
      x: 699.3961365609575,
      y: -911.583596363847,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 1929.2601396864752,
        h: 4061.904786107253,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a8",
      id: "shape:Mgc6apq_jXdGKjHU_QFud",
      typeName: "shape",
    },
    "shape:-D41zKShKqdqIMpI9WnFH": {
      x: 179.97144798776955,
      y: 1923.9301588205565,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2448.6810533392436,
        h: 788.16807921665,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4l",
      id: "shape:-D41zKShKqdqIMpI9WnFH",
      typeName: "shape",
    },
    "shape:piKJysMFJ46YvlbZronGe": {
      x: 1387.4286052088323,
      y: 1885.9318825077394,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "m",
        w: 8,
        text: "",
        font: "draw",
        align: "start",
        autoSize: true,
        scale: 1,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aA",
      id: "shape:piKJysMFJ46YvlbZronGe",
      typeName: "shape",
    },
    "shape:dG462qJt5KCBwoNPdv_VD": {
      x: 211.62330250381544,
      y: -637.6796549290171,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "EDD",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aB",
      id: "shape:dG462qJt5KCBwoNPdv_VD",
      typeName: "shape",
    },
    "shape:SO2jH1MvT8MIX-a4akrzw": {
      x: 211.15180847572802,
      y: -188.1087044660918,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Medical Issues",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aC",
      id: "shape:SO2jH1MvT8MIX-a4akrzw",
      typeName: "shape",
    },
    "shape:bvrqvEZt6WgvE4hMgv2n9": {
      x: 212.5981353196457,
      y: -429.7650361778102,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Gestation",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aD",
      id: "shape:bvrqvEZt6WgvE4hMgv2n9",
      typeName: "shape",
    },
    "shape:r5oAjwfM6uSfhoelXtPZu": {
      x: 213.69899499834565,
      y: -852.53213691224,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 343.37092212625043,
        text: "Parity/Children",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aE",
      id: "shape:r5oAjwfM6uSfhoelXtPZu",
      typeName: "shape",
    },
    "shape:daNAZfxNctdb5kZZV2IPb": {
      x: 248.11931539500574,
      y: 2239.153081619434,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 355.5070794047069,
        text: "Consultation and Scan",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 0.9303543628087811,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aF",
      id: "shape:daNAZfxNctdb5kZZV2IPb",
      typeName: "shape",
    },
    "shape:NbURJh6DToXVIMmWf0KyK": {
      x: 241.45253102773904,
      y: 2861.247446565701,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 243.68324433628666,
        text: "Additional comments",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0560802651415109,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aG",
      id: "shape:NbURJh6DToXVIMmWf0KyK",
      typeName: "shape",
    },
    "shape:mUEr69JCEJbxfS57fAXB0": {
      x: 225.5840465491271,
      y: 67.10319348323503,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 319.56133322882874,
        text: "Medications",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCV",
      id: "shape:mUEr69JCEJbxfS57fAXB0",
      typeName: "shape",
    },
    "shape:MRDcwxXBkbB_K0FyfbHMf": {
      x: 176.03391241760755,
      y: 217.81832126878066,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 313.90563432143284,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2G",
      id: "shape:MRDcwxXBkbB_K0FyfbHMf",
      typeName: "shape",
    },
    "shape:o3ioUjFdW1tpZlqg9-HDE": {
      x: 176.37537080615812,
      y: 837.6607809004352,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2451.048801945889,
        h: 313.90563432143284,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a2l",
      id: "shape:o3ioUjFdW1tpZlqg9-HDE",
      typeName: "shape",
    },
    "shape:n0f1gDNlilWsj9H9qN6PP": {
      x: 176.3478996916774,
      y: 529.7017087984257,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 312.239363698155,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a3V",
      id: "shape:n0f1gDNlilWsj9H9qN6PP",
      typeName: "shape",
    },
    "shape:QreRJw7Q1XQMw7dGxWYB7": {
      x: 176.68935808022798,
      y: 1150.0995919711731,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 435.9551009931502,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a4G",
      id: "shape:QreRJw7Q1XQMw7dGxWYB7",
      typeName: "shape",
    },
    "shape:SNOqVVvLrVUqx9WDCLwhJ": {
      x: 177.512894074619,
      y: 1588.754540197601,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "geo",
      props: {
        w: 2449.727558805954,
        h: 335.04471489070704,
        geo: "rectangle",
        color: "black",
        labelColor: "black",
        fill: "none",
        dash: "draw",
        size: "m",
        font: "draw",
        text: "",
        align: "start",
        verticalAlign: "middle",
        growY: 0,
        url: "",
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "a5V",
      id: "shape:SNOqVVvLrVUqx9WDCLwhJ",
      typeName: "shape",
    },
    "shape:phkruREoIZnlz0XQ_Sjku": {
      x: 235.75209180502475,
      y: 333.5369873238782,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Obstetric history",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCl",
      id: "shape:phkruREoIZnlz0XQ_Sjku",
      typeName: "shape",
    },
    "shape:XSqxnyL2Q8O2ob9O4kbEX": {
      x: 237.0390105995673,
      y: 653.25753993265,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Medical History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCt",
      id: "shape:XSqxnyL2Q8O2ob9O4kbEX",
      typeName: "shape",
    },
    "shape:c_5ctf3xkiGM3QSIaHGuW": {
      x: 233.88536616343276,
      y: 937.4535866960026,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Surgical History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCx",
      id: "shape:c_5ctf3xkiGM3QSIaHGuW",
      typeName: "shape",
    },
    "shape:8TV3KdMvAmv2TRT3VeBtI": {
      x: 221.85059526594318,
      y: 1310.460898072903,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Gynae History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCz",
      id: "shape:8TV3KdMvAmv2TRT3VeBtI",
      typeName: "shape",
    },
    "shape:ERcCa9hSIOVsKtreDbcn3": {
      x: 236.45920375251808,
      y: 1696.7898991418356,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      type: "text",
      props: {
        color: "black",
        size: "xl",
        w: 412.01989113089826,
        text: "Family History",
        font: "serif",
        align: "start",
        autoSize: false,
        scale: 1.0566073416197603,
      },
      parentId: "page:2PcVW5SiUALSaOA3S30TJ",
      index: "aCzV",
      id: "shape:ERcCa9hSIOVsKtreDbcn3",
      typeName: "shape",
    },
  },
  schema: {
    schemaVersion: 1,
    storeVersion: 4,
    recordVersions: {
      asset: {
        version: 1,
        subTypeKey: "type",
        subTypeVersions: { image: 2, video: 2, bookmark: 0 },
      },
      camera: { version: 1 },
      document: { version: 2 },
      instance: { version: 20 },
      instance_page_state: { version: 4 },
      page: { version: 1 },
      shape: {
        version: 3,
        subTypeKey: "type",
        subTypeVersions: {
          group: 0,
          text: 1,
          bookmark: 1,
          draw: 1,
          geo: 7,
          note: 4,
          line: 1,
          frame: 0,
          arrow: 1,
          highlight: 0,
          embed: 4,
          image: 2,
          video: 1,
        },
      },
      instance_presence: { version: 5 },
      pointer: { version: 1 },
    },
  },
};
