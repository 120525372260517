import { AZURE_URL } from "../constants/env-constants";

export const triggerWixFetchCall = async (startDate: Date, toDate: Date) => {
  try {
    const response = await AZURE_URL.post(`/booking/loadWixBookings`, {
      startDate: startDate,
      toDate: toDate,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getAppointmentsWithDate = async (formData: {
  fromDate: string;
  toDate: string;
}) => {
  try {
    const response = await AZURE_URL.get("/booking/get", { params: formData });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getAppointmentByPatientId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(`/booking/get?patientPublicId=${id}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
