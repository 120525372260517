import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import CustomBox from "../buttons/CustomBox";
import {
  BRAND_COLOR_LIGHT,
  getClientBrandColorLight,
} from "../../constants/BrandConstants";
import {
  ALLERGY_BG_COLOR_RED,
  GENDER_COLOR_BLUE,
  GENDER_COLOR_PINK,
  TEXT_COLOR_BLACK,
  TEXT_COLOR_WHITE,
} from "../../constants/ColorsConstants";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PatientEditForm from "./PatientEditForm";
import { FEMALE, MALE } from "../../constants/Constants";
import NotificationAndAlerts from "./NotificationAndAlerts";
import CapturePhoto from "../photo/CapturePhoto";
import SecondaryButton from "../buttons/SecondaryButton";
import ImageClickUploader from "../fromComponents/ImageClickUploader";
import PatientInformationDialog from "./test/PatientInformationDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ActionShowDetails from "../actionIcons/ActionShowDetails";
import { calculateBMI } from "../../util/helpers/BMICalculator";

const PatientBasicDetails = React.memo<PatientDetailsProps>(({}) => {
  const patientObject: any = useSelector<PatientState>(
    (state) => state.patient
  );
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const patient: PatientInformation = patientObject.patient;
  const { partnerPublicId, allergies } = patient;
  const [initialData, setInitialData] = useState<PatientInformation>();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [photoData, setPhotoData] = useState("");
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const handleCapture = async (data: string) => {
    setPhotoData(data);
    setOpenModal(false);
    console.log(data);
  };
  useEffect(() => {}, [reload]);
  return (
    <>
      <Card sx={{ p: 2, borderRadius: 5, height: "100%" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <ImageClickUploader patient={patient} />
            {/* <PrimaryButton
              label={"Add Picture"}
              type={"button"}
              onClick={() => setOpenModal(true)}
            />
            <Button variant="contained" onClick={() => setOpenModal(true)}>
              Add picture
            </Button> */}
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <Box paddingBottom={2}>
              <CustomBox
                label={patient.publicId}
                color={getClientBrandColorLight()}
                textColor="white"
              />
              {patient?.gender === FEMALE ? (
                <CustomBox
                  label={FEMALE}
                  color={GENDER_COLOR_PINK}
                  textColor={TEXT_COLOR_BLACK}
                />
              ) : patient?.gender === MALE ? (
                <CustomBox
                  label={MALE}
                  color={GENDER_COLOR_BLUE}
                  textColor={TEXT_COLOR_WHITE}
                />
              ) : null}

              {/* <Button
                children={<NotificationsActiveOutlinedIcon />}
                onClick={() => console.log("first")}
              /> */}
              {/* temp removed */}
              {/* <IconAction
                icon={<NotificationsActiveOutlinedIcon />}
                badgeValue={5}
                onClick={() => setIsNotificationOpen(true)}
              /> */}
            </Box>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={"bold"}>
                  {`${patient.title ? patient.title + " " : ""}${
                    patient.firstName ? patient.firstName + " " : ""
                  }${patient.lastName ? patient.lastName : ""}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">DOB</Typography>
              </Grid>
              <Grid item xs={9}>
                {patient.dateOfBirth && (
                  <Typography variant="body1">
                    {`${patient.dateOfBirth ? patient.dateOfBirth : ""} (${
                      patient.age
                    })`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">Email</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1">{`${
                  patient.email ? patient.email : ""
                }`}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">Address</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1">{`${
                  patient.address
                    ? (patient.address.street1 || "") +
                      ", " +
                      (patient.address.postalCode || "")
                    : ""
                }`}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">BMI</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1">
                  {calculateBMI(
                    patient.patientDetails?.weight || 0, // Default to 0 if weight is undefined
                    patient.patientDetails?.weightUnit || "kg", // Default to "kg" if weightUnit is undefined
                    patient.patientDetails?.height || 0, // Default to 0 if height is undefined
                    patient.patientDetails?.heightUnit || "cm" // Default to "cm" if heightUnit is undefined
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">Allergies</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1">
                  {allergies?.length === 0 ? (
                    <Typography variant="body1" color={"error"}>
                      Allergies not added
                    </Typography>
                  ) : (
                    allergies?.map((allergy: string) => (
                      <CustomBox
                        label={allergy}
                        color={ALLERGY_BG_COLOR_RED}
                        textColor={TEXT_COLOR_BLACK}
                      />
                    ))
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              {/* {showDetails && (
                <SecondaryButton
                  className={"m-2"}
                  type={"button"}
                  label={`Hide Details `}
                  onClick={() => setShowDetails(false)}
                />
              )}
              
              <SecondaryButton
                className={"m-2"}
                type={"button"}
                label={"Show Details"}
                onClick={() => setShowDetails(true)}
              /> */}

              <SecondaryButton
                className={"m-2"}
                label={"Edit"}
                type={"button"}
                startIcon={<EditIcon />}
                onClick={() => {
                  setIsEdit(true);
                  setInitialData(patient);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Typography variant="subtitle1">
                Consultant
                {` ${
                  patient?.ownerStaff?.title
                    ? patient.ownerStaff?.title + " "
                    : ""
                } ${
                  patient?.ownerStaff?.firstName
                    ? patient.ownerStaff?.firstName + " "
                    : ""
                } ${
                  patient?.ownerStaff?.lastName
                    ? patient.ownerStaff?.lastName + " "
                    : ""
                }`}
              </Typography>
              <Typography variant="body1"></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {openModal && (
        <CapturePhoto
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={handleCapture}
        />
      )}

      {isEdit && initialData && (
        <PatientEditForm
          isOpen={isEdit}
          setIsOpen={setIsEdit}
          header={"header"}
          initialData={initialData}
          formFields={[]}
          setReload={setReload}
        />
      )}

      {/* <DialogPatientInformation
        isOpen={showDetails}
        patient={patientObject.patient}
        setIsOpen={setShowDetails}
      /> */}
      <PatientInformationDialog
        open={showDetails}
        onClose={() => setShowDetails(!showDetails)}
        onSave={(data: PatientInformation) => {
          // Handle saving the updated values here
          console.log(data);
        }}
        patientInformation={{
          publicId: "P485979",
          title: "Mr.",
          firstName: "Test",
          lastName: "Patient 2",
          gender: "Male",
          age: 2,
          email: "admin@p4fertility.co.uk",
          phoneNo: "07867032864",
          dateOfBirth: "2016-02-03T00:00:00.000+00:00",
          address: {
            street1: "Wheelyes Road",
            city: "Birmingham",
            state: "West Midlands",
            postalCode: "B15 2LL",
            country: "United Kingdom",
          },
          partnerPublicId: "P061731",
          ownerStaff: {
            publicId: "550e7d15-651c-4ab3-9591-c9317d6f8bed",
            username: "p4fertility",
            title: "Mr.",
            firstName: "Yadava",
            lastName: "Jeve",
            email: "p4fertility1@gmail.com",
            phoneNo: "+447594119742",
            signature: "Mr.Yadava Jeve",
            createdBy: "p4fertility",
            whenCreated: "2023-03-22T11:12:40.453+00:00",
            siteExternalId: "",
          },
          patientDetails: {
            allergies: "Bananna,HeLoKjnnsu,Banana Bread",
          },
          createdBy: "yadava.jeve",
          whenCreated: "2023-06-01T09:02:06.783+00:00",
        }}
      />

      <NotificationAndAlerts
        isOpen={isNotificationOpen}
        setIsOpen={setIsNotificationOpen}
      />
    </>
  );
});

export default PatientBasicDetails;
