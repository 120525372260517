import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, TextField, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import { loginUser } from "../../apis/auth";
import { getUserDetails } from "../../apis/users";
import { LOGIN_FAILED, SNACKBAR_ERROR } from "../../constants/Constants";
import { login } from "../../redux/slice/authSlice";
import { removeLoginDetails } from "../../redux/slice/loginSlice";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { addUserCredentials } from "../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../buttons/SecondaryButton";
import TernaryButton from "../buttons/TernaryButton";
import PrimaryButton from "../buttons/PrimaryButton";

type TwoFactorAuthCodeProps = {
  showBack?: boolean;
  setHideQRView?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TwoFactorAuthCode: React.FC<TwoFactorAuthCodeProps> = ({
  setHideQRView,
  showBack,
}) => {
  const [code, setCode] = useState<string>("");
  const [clientId, setClientId] = useState<string>("p4pregnancy");
  const loginObject: any = useSelector<Login>((state) => state.login);
  const { username, password } = loginObject.login;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const codeInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    if (code.length === 6) {
      dispatch(loading());
      if (username && password) {
        const [response, error] = await loginUser(
          { username, password, clientId },
          code
        );
        if (response) {
          dispatch(login(response));
          let { data } = await getUserDetails();

          let userRoles: string[] = [];
          for (const role of data.roles) {
            userRoles.push(role.name);
          }
          data.roles = userRoles;
          if (data.firstLogin) {
          }
          dispatch(addUserCredentials(data));
          dispatch(removeLoginDetails());
          dispatch(doneLoading());
          setCode("");
          navigate("/dashboard", { replace: true });
        } else {
          dispatch(doneLoading());
          dispatch(
            activateSnackbar({
              active: true,
              message: error.response.data || LOGIN_FAILED,
              variant: SNACKBAR_ERROR,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (codeInputRef.current) {
      codeInputRef.current.focus();
    }
    dispatch(doneLoading());
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Grid container direction="column" spacing={2} maxWidth={400}>
        <Grid item>
          <Typography variant="h6" align="left" gutterBottom>
            Authentication
          </Typography>
          <Typography align="left">
            Open the authenticator app on your device and enter the 6-digit code
            below.
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            id="code"
            label="Enter 6-digit code"
            type="number"
            inputProps={{
              length: 6,
            }}
            autoFocus
            value={code}
            onChange={(e) => {
              const inputCode = e.target.value;
              if (/^\d{0,6}$/.test(inputCode)) {
                setCode(inputCode);
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item container justifyContent="flex-end" spacing={2}>
          <Grid item>
            {showBack ? (
              setHideQRView && (
                <TernaryButton
                  onClick={() => setHideQRView(false)}
                  color="primary"
                  label="Back"
                />
              )
            ) : (
              <TernaryButton
                onClick={() => {
                  navigate("/login", { replace: true });
                }}
                color="primary"
                label="Cancel"
              />
            )}
          </Grid>
          <Grid item>
            <PrimaryButton
              label={"Sign In"}
              type={"button"}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TwoFactorAuthCode;
