import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

interface BMIProps {
  onCalculateBMI: (bmi: number) => void;
  handleChange: (name: string, value: string | number) => void;
}

const BMICalculator: React.FC<BMIProps> = ({
  onCalculateBMI,
  handleChange,
}) => {
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [height, setHeight] = useState("");
  const [heightUnit, setHeightUnit] = useState("cm");
  const [bmi, setBMI] = useState(0);

  useEffect(() => {
    calculateBMI();
  }, [weight, weightUnit, height, heightUnit]);
  useEffect(() => {
    handleChange("patientDetails.weightUnit", "kg");
    handleChange("patientDetails.heightUnit", "cm");
  }, []);
  const calculateBMI = () => {
    const weightValue = parseFloat(weight);
    const heightValue = parseFloat(height);
    let weightMultiplier = 1;
    let heightMultiplier = 1;

    if (
      isNaN(weightValue) ||
      isNaN(heightValue) ||
      weightValue <= 0 ||
      heightValue <= 0
    ) {
      setBMI(0);
      return;
    }

    let bmiValue;

    if (weightUnit === "kg") {
      weightMultiplier = 1;
    } else if (weightUnit === "lbs") {
      weightMultiplier = 0.45359237; // 1 lb = 0.45359237 kg
    } else if (weightUnit === "stone") {
      weightMultiplier = 6.35029318; // 1 stone = 6.35029318 kg
    }

    if (heightUnit === "cm") {
      heightMultiplier = 0.01; // Convert cm to meters
    } else if (heightUnit === "in") {
      heightMultiplier = 0.0254; // 1 inch = 0.0254 meters
    } else if (heightUnit === "ft") {
      heightMultiplier = 0.3048; // 1 foot = 0.3048 meters
    }

    bmiValue =
      weightValue /
      (heightValue * heightMultiplier * heightValue * heightMultiplier);
    setBMI(bmiValue);
    onCalculateBMI(bmiValue);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value);
    handleChange(event.target.name, event.target.value);
  };

  const handleWeightUnitChange = (event: SelectChangeEvent<string>) => {
    setWeightUnit(event.target.value);
    handleChange(event.target.name, event.target.value);
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(event.target.value);
    handleChange(event.target.name, event.target.value);
  };

  const handleHeightUnitChange = (event: SelectChangeEvent<string>) => {
    setHeightUnit(event.target.value);
    handleChange(event.target.name, event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={2} alignItems="center" height="100%">
        <TextField
          label="Weight"
          name="patientDetails.weight"
          value={weight}
          type="number"
          onChange={handleWeightChange}
        />

        <Select
          value={weightUnit}
          name="patientDetails.weightUnit"
          onChange={handleWeightUnitChange}
        >
          <MenuItem value="kg">Kg</MenuItem>
          <MenuItem value="lbs">Lbs</MenuItem>
          <MenuItem value="stone">St</MenuItem>
        </Select>

        <TextField
          label="Height"
          value={height}
          name="patientDetails.height"
          type="number"
          onChange={handleHeightChange}
        />

        <Select
          value={heightUnit}
          name="patientDetails.heightUnit"
          onChange={handleHeightUnitChange}
        >
          <MenuItem value="cm">Cm</MenuItem>
          <MenuItem value="in">In</MenuItem>
          <MenuItem value="ft">Ft</MenuItem>
        </Select>
        {bmi > 0 && <Typography variant="h6">BMI: {bmi.toFixed(2)}</Typography>}
      </Box>
    </Box>
  );
};

export default BMICalculator;
