import { AZURE_URL } from "../constants/env-constants";

export const createLetter = async (data: P4LetterRequest) => {
  try {
    const response = await AZURE_URL.post("/letter/create", data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getLetters = async (data: P4LetterRequest) => {
  try {
    const response = await AZURE_URL.get("/letter/get/all");

    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error "];
  }
};

export const getLettersByPatientId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(`letter/get?patientPublicId=${id}`);

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};
