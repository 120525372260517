import axios from "axios";
import { store } from "../redux/store/store";
const URLS = {
  // P4_BACKEND: process.env.P4_BACKEND || "http://localhost:8080/api",
  p4_AZURE: process.env.REACT_APP_API_URL, //dev
  // p4_AZURE: "https://dev.p4crm-api.swivo.io", //test
  // p4_AZURE: "https://prod.p4crm-api.swivo.io", //prod
};

// export const API_URL = () =>
//   axios.create({
//     baseURL: URLS.P4_BACKEND,
//     headers: {
//       // "Content-Type": "application/pdf",
//       // Authorization: "Bearer " + sessionStorage.getItem(ACCESS_TOKEN),
//       AllowOrigin: "*",
//     },
//   });

export const AZURE_URL = axios.create({
  baseURL: URLS.p4_AZURE,
});

AZURE_URL.interceptors.request.use((config) => {
  const state = store.getState();
  const { token, tokenType } = state.auth.auth;
  config.headers.Authorization = `${tokenType} ${token}`;
  return config;
});
let isTokenExpiredAlertShown = false;

AZURE_URL.interceptors.response.use(
  (response) => {
    // console.log(response);
    return response;
  },
  (error) => {
    // const dispatch = useDispatch();
    // console.log(error);
    if (error.response.status === 403 && !isTokenExpiredAlertShown) {
      isTokenExpiredAlertShown = true; // Set the flag to true to indicate that the logic has been executed
      alert("Access Token expired. Please logout and login again.");
      // dispatch(logout());
      window.localStorage.clear();

      window.location.reload();
    } else {
      return Promise.reject(error); // Return a rejected Promise to propagate the error to the next catch block
    }
  }
);

// Set the maximum number of API calls
const MAX_API_CALLS = 20;

// Keep track of the last five API calls
let lastFiveAPICalls: (string | undefined)[] = [];

// Add a request interceptor
AZURE_URL.interceptors.request.use(function (config) {
  // Check if the last five API calls are the same
  if (
    lastFiveAPICalls.length === MAX_API_CALLS &&
    lastFiveAPICalls.every((call) => call === config.url)
  ) {
    return Promise.reject(new Error("All five API calls are the same."));
  }

  // Remove the oldest API call if the maximum number of API calls has been reached
  if (lastFiveAPICalls.length === MAX_API_CALLS) {
    lastFiveAPICalls.shift();
  }

  // Add the current API call to the last five API calls
  lastFiveAPICalls.push(config.url);

  return config;
});
