import { AZURE_URL } from "../constants/env-constants";

export const createDocument = async (data: CreateDocumentRequest) => {
  try {
    const response = await AZURE_URL.post("/document/create", data);

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const getDocuments = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/document/get?patientPublicId=${patientId}`
    );

    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const getDocumentsByType = async (type: string) => {
  try {
    const response = await AZURE_URL.get(`/document/get?type=${type}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const deleteDocumentById = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/document/delete?publicId=${id}`);

    return ["Success", null];
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};
