import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  getClientBrandColorDark,
  getClientLogo,
} from "../../../constants/BrandConstants";
import ProfileAvatar from "./ProfileAvatar";
import { useSelector } from "react-redux";
import { AppBar, Box, Toolbar, styled } from "@mui/material";
import InitialAPICalls from "../../initialRendering/InitialAPICalls";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "#050505",
  minHeight: 0,
}));

const linkStyles = {
  textDecoration: "none",
  fontWeight: "500",
  borderBottom: "none",
  transition: "color 0.3s, border-bottom 0.3s", // Add transition for smooth effect
};

const NavigationBar = () => {
  const userObject: any = useSelector<UserState>((state) => state.user);
  const location = useLocation();
  const mainNavigation = [
    { path: "/dashboard", label: "Dashboard" },
    { path: "/patients", label: "Patients" },
    { path: "/labs", label: "Labs" },
    { path: "/admin-center", label: "Admin Center" },
  ];

  const isMenuSelected = (path: string) => location.pathname.includes(path);

  const { firstName, lastName } = userObject.user.staff;

  return (
    <StyledAppBar position="static" elevation={0}>
      <InitialAPICalls />
      <Toolbar sx={{ justifyContent: "space-between", minHeight: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/dashboard" style={{ marginRight: "20px" }}>
            <img src={getClientLogo()} alt="logo" height="35" width="130" />
          </Link>
          {mainNavigation.map((menuItem) => (
            <Box key={menuItem.path} sx={{ mr: 2 }}>
              <Link
                to={menuItem.path}
                style={{
                  ...linkStyles,
                  color: isMenuSelected(menuItem.path)
                    ? getClientBrandColorDark()
                    : "#808080",
                  borderBottom: isMenuSelected(menuItem.path)
                    ? `1px solid ${getClientBrandColorDark()}`
                    : "none",
                }}
              >
                {menuItem.label}
              </Link>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ProfileAvatar
            user={{
              firstName,
              lastName,
              image: undefined,
            }}
          />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default NavigationBar;
