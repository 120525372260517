import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import {
  RedoOutlined,
  Save,
  UndoOutlined,
  TouchAppOutlined,
  DeleteForever,
  CollectionsOutlined,
  RemoveCircleOutlineOutlined,
  AddCircleOutlineOutlined,
  NoteAddOutlined,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faPen } from "@fortawesome/free-solid-svg-icons";
import NotepadImageModal from "./ImageDialog";

const Toolbar: React.FC<SketchpadToolbarProps> = ({
  clearCanvas,
  toolbarOptions,
  handleUndo,
  handleRedo,
  saveAsSVG,
  handlePrint,
  setBoardHeight,
  undoDisabled,
  redoDisabled,
  setPenColor,
  selectedPenColor,
  setPenSize,
  selectedPenSize,
  setIsEraserActive,
  isEraserActive,
  setEraserSize,
  selectedEraserSize,
  enableTouch,
  setEnableTouch,
  addDiagrams,
  setSelectedImage,
}) => {
  const penColors: ColorOption[] = [
    { label: "Black", value: "black" },
    { label: "Red", value: "red" },
    { label: "Blue", value: "blue" },
    { label: "Green", value: "green" },
    { label: "Yellow", value: "yellow" },
    // Add more color options as needed
  ];

  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const handlePenColorChange = (value: string) => {
    setPenColor(value);
  };

  const handlePenSizeChange = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      setPenSize(value);
    }
  };

  const toggleEraser = () => {
    setIsEraserActive((prevState) => !prevState);
  };

  const handleSizeChange = (event: Event, newSize: number | number[]) => {
    if (typeof newSize === "number") {
      setEraserSize(newSize * 2);
    }
  };

  const handleExpandHeight = () => {
    setBoardHeight((prevHeight) => prevHeight + 1000);
  };
  const handleReduceHeight = () => {
    setBoardHeight((prevHeight) => {
      const newHeight = prevHeight - 1000;
      return newHeight >= 0 ? newHeight : prevHeight;
    });
  };
  useEffect(() => {
    setOpenImageModal(false);
  }, [setSelectedImage]);
  return (
    <Grid container className="toolbarContainer">
      <Grid item className="toolbarColorSelector">
        {toolbarOptions.showColors && (
          <RadioGroup
            row
            value={selectedPenColor}
            onChange={(e) => handlePenColorChange(e.target.value)}
          >
            {penColors.map((color) => (
              <FormControlLabel
                key={color.value}
                value={color.value}
                control={<Radio style={{ color: color.value }} />}
                style={{
                  borderRadius: "50%",
                  marginRight: "1px",
                  margin: 3,
                  padding: 0,
                  width: "28px",
                  height: "28px", // Set the desired height
                }}
                label={undefined}
              />
            ))}
          </RadioGroup>
        )}{" "}
        <input
          id="custom-color"
          type="color"
          style={{
            borderRadius: "50%",
            marginRight: "1px",
            margin: 3,
            marginLeft: 10,
            padding: 0,
            cursor: "pointer",
            width: "22px", // Set the desired width
            height: "22px", // Set the desired height
          }}
          onChange={(e) => handlePenColorChange(e.target.value)}
        />
      </Grid>

      <Grid item className="toolbarPenSize" sx={{ width: 150 }}>
        <IconButton
          onClick={toggleEraser}
          aria-label="Toggle Eraser"
          color={isEraserActive ? "default" : "primary"}
        >
          <FontAwesomeIcon icon={faPen} />
        </IconButton>
        {toolbarOptions.showPencilSize && (
          <Slider
            value={selectedPenSize}
            onChange={handlePenSizeChange}
            min={1}
            max={5}
            step={1}
            valueLabelDisplay="auto"
            marks
          />
        )}
      </Grid>

      <Grid item className="toolbarEraser" sx={{ width: 150 }}>
        <IconButton
          onClick={toggleEraser}
          aria-label="Toggle Eraser"
          color={isEraserActive ? "primary" : "default"}
        >
          <FontAwesomeIcon icon={faEraser} />
        </IconButton>
        {toolbarOptions.showEraserSize && (
          <Slider
            value={selectedEraserSize / 2}
            min={1}
            max={10}
            step={2}
            onChange={handleSizeChange}
            marks
          />
        )}
      </Grid>

      <Grid item className="toolbarButtons">
        <IconButton
          onClick={handleUndo}
          disabled={undoDisabled}
          aria-label="Undo"
          color={!undoDisabled ? "primary" : "default"}
        >
          <UndoOutlined />
        </IconButton>
        <IconButton
          onClick={handleRedo}
          disabled={redoDisabled}
          aria-label="Redo"
          color={!redoDisabled ? "primary" : "default"}
        >
          <RedoOutlined />
        </IconButton>
        {toolbarOptions.showTouch && (
          <IconButton
            onClick={() => setEnableTouch((prev) => !prev)}
            aria-label="Touch"
            color={!enableTouch ? "primary" : "default"}
          >
            <TouchAppOutlined />
          </IconButton>
        )}
        {toolbarOptions.showAddRemoveHeight && (
          <IconButton
            onClick={handleExpandHeight}
            aria-label="Expand Height"
            color="primary"
          >
            <AddCircleOutlineOutlined />
          </IconButton>
        )}
        {toolbarOptions.showAddRemoveHeight && (
          <IconButton
            onClick={handleReduceHeight}
            aria-label="Reduce Height"
            color="error"
          >
            <RemoveCircleOutlineOutlined />
          </IconButton>
        )}
        {saveAsSVG && (
          <IconButton
            onClick={saveAsSVG}
            aria-label="Save as SVG"
            color="primary"
          >
            <Save />
          </IconButton>
        )}
        {/* <IconButton onClick={handlePrint} aria-label="Print" color="primary">
          <PrintOutlined />
        </IconButton> */}
        {/* <IconButton aria-label="Add Diagrams" color="primary">
          <NoteAddOutlined />
        </IconButton> */}
        {toolbarOptions.showAddDiagrams && (
          <IconButton
            onClick={() => setOpenImageModal(true)}
            aria-label="Collection"
            color="primary"
          >
            <CollectionsOutlined />
          </IconButton>
        )}
        {/* <DropdownIconButton /> */}
        <IconButton
          onClick={clearCanvas}
          disabled={undoDisabled}
          aria-label="Clear"
          color={!undoDisabled ? "error" : "default"}
        >
          <DeleteForever />
        </IconButton>
      </Grid>
      {setSelectedImage && (
        <NotepadImageModal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          setSelectedImage={setSelectedImage}
        />
      )}
    </Grid>
  );
};

export default Toolbar;
