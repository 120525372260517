import { AZURE_URL } from "../constants/env-constants";
export const createNewNote = async (note: CreateNote) => {
  try {
    const response = await AZURE_URL.post("/note/create", note);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getPatientNotes = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/note/get?patientPublicId=${patientId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};
