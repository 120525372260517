import { AZURE_URL } from "../constants/env-constants";

export const createReport = async (data: CreateReportRequest) => {
  try {
    const response = await AZURE_URL.post("/report/create", data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data || "Server error"];
  }
};

export const getAllReports = async (patientId: string) => {
  try {
    const response = await AZURE_URL.get(
      `/report/get?patientPublicId=${patientId}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const getReportsByType = async (patientId: string, type: string) => {
  try {
    const response = await AZURE_URL.get(
      `/report/get?patientPublicId=${patientId}&type=${type}`
    );
    return [response.data, null];
  } catch (error: any) {
    console.log(error);
    return [null, error.response.data];
  }
};

export const deleteReportById = async (reportId: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/report/delete?publicId=${reportId}`
    );
    return [response.status, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};
