import { AZURE_URL } from "../constants/env-constants";

export const createPrescription = async (
  Prescription: CreatePrescriptionRequest
) => {
  try {
    const response = await AZURE_URL.post("/prescription/create", Prescription);
    return [response.data, null];
  } catch (error: any) {
    return [null, "Server error: " + error.response];
  }
};

export const getPrescriptionsByPatientId = async (id: string) => {
  try {
    const response = await AZURE_URL.get(
      `/prescription/get?patientPublicId=${id}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};

export const deletePrescription = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/prescription/delete?publicId=${id}`
    );

    return [response, null];
  } catch (error: any) {
    return [null, "Server Error: " + error.message];
  }
};
